import React from "react";
import { withRouter } from "react-router-dom";
import CampaignEmailForm from "./campaign-email-form";
import {Card, Col, PageHeader, Row} from "antd";

const Page = ({ match }) => {
    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Campañas \ Emails"
                backIcon={null}
                subTitle="Enviar emails a contactos"
            />
            <Card>
                <Row gutter={16}>
                    <Col md={24}>
                        <h2>Enviar emails a contactos</h2>
                    </Col>
                    <Col md={24}>
                        <CampaignEmailForm id={match.params.id}/>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default withRouter(Page);