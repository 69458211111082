import React, { useEffect, useState } from "react";
import { Form, Icon, Input, Button, Row, Col } from "antd";
import Cliente from "../../../api/Cliente";
import * as configService from "../../../api/services/config.service";

const ConfiguracionGestorForm = props => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadConfig();
  }, []);

  const loadConfig = async () => {
    const result = await configService.get();

    const config = result.data;

    props.form.setFields({
      API_KEY: {
        value: config.API_KEY
      },
      API_SECRET: {
        value: config.API_SECRET
      },
      CRON_DAYS: {
        value: config.CRON_DAYS
      },
      EMAIL_FROM: {
        value: config.EMAIL_FROM
      },
      TEMPLATE_ID: {
        value: config.TEMPLATE_ID
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        try {
          await Cliente.setBearerToken()
            .getCliente()
            .post(`${process.env.REACT_APP_API_URL}config/`, {
              ...values
            });
        } catch (err) {
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <>
      <Form onSubmit={handleSubmit} className="login-form" autoComplete="off">
        <Row gutter={16}>
          <Col md={8}>
            <Form.Item required label="API_KEY" style={{ marginBottom: 0 }} />
            <Form.Item>
              {getFieldDecorator("API_KEY", {
                rules: [
                  { required: true, message: "El campo API_KEY es requerido" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="setting" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="API_KEY"
                />
              )}
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item
              required
              label="API_SECRET"
              style={{ marginBottom: 0 }}
            />
            <Form.Item>
              {getFieldDecorator("API_SECRET", {
                rules: [
                  {
                    required: true,
                    message: "El campo API_SECRET es requerido"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="setting" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="API_SECRET"
                />
              )}
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item required label="CRON_DAYS" style={{ marginBottom: 0 }} />
            <Form.Item>
              {getFieldDecorator("CRON_DAYS", {
                rules: [
                  { required: true, message: "El campo CRON_DAYS es requerido" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="setting" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="CRON_DAYS"
                />
              )}
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item
              required
              label="EMAIL_FROM"
              style={{ marginBottom: 0 }}
            />
            <Form.Item>
              {getFieldDecorator("EMAIL_FROM", {
                rules: [
                  {
                    required: true,
                    message: "El campo EMAIL_FROM es requerido"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="setting" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="EMAIL_FROM"
                />
              )}
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item
              required
              label="TEMPLATE_ID"
              style={{ marginBottom: 0 }}
            />
            <Form.Item>
              {getFieldDecorator("TEMPLATE_ID", {
                rules: [
                  {
                    required: true,
                    message: "El campo TEMPLATE_ID es requerido"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="setting" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="TEMPLATE_ID"
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="text-center">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
          >
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create({ name: "config_form" })(ConfiguracionGestorForm);
