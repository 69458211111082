import Cliente from "../Cliente";

export const list = () => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}templates/`);
};

export const get = (id) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}templates/${id}`);
};

export const create = (formData) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}templates`, formData);
};

export const edit = (id, formData) => {
    return Cliente.setBearerToken()
        .getCliente()
        .put(`${process.env.REACT_APP_API_URL}templates/${id}`, formData);
};

export const remove = id => {
    return Cliente.setBearerToken()
        .getCliente()
        .delete(`${process.env.REACT_APP_API_URL}templates/${id}`);
};
