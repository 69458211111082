import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Icon,
  Input,
  Button,
  Select,
  Row,
  Col,
  PageHeader,
  Switch,
  Upload,
  Table,
  Divider,
  message
} from "antd";
import Cliente from "../../api/Cliente";
import * as service from "../../api/services/country.service";
import * as siteService from "../../api/services/site.service";

const InterconectingShowForm = props => {
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [site, setSite] = useState();
  const [fileList, setFileList] = useState([]);
  const [dataCSV, setDataCSV] = useState([]);

  const columns = [
    {
      title: "WEB",
      dataIndex: "web",
      key: "web"
    },

    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email"
    }
  ];

  useEffect(() => {
    loadCountries();
    loadSite(props.match.params.id);
  }, []);

  const loadCountries = async () => {
    try {
      const result = await service.getCountries();
      setLoading(false);
      setCountries(result.data);
    } catch (err) {}
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await Cliente.getCliente().put(
            `${process.env.REACT_APP_API_URL}site/${props.match.params.id}`,
            {
              ...values
            }
          );
        } catch (err) {}
      }
    });
  };

  const beforeUpload = file => {
    const arr = file.name.split(".");
    const ext = arr[arr.length - 1].toLowerCase();

    if (ext !== "csv") {
      message.error("Archivo no disponible");
      return;
    }

    setFileList([file]);
    return false;
  };

  const populate = data => {
    let result = [];
    data.map((csv, idx) => {
      result.push({
        key: idx,
        web: csv.WEB,
        email: csv.EMAIL
      });
    });

    setDataCSV(result);
  };

  const onRemove = () => {
    setFileList([]);
    return {
      fileList: []
    };
  };

  const loadSite = async id => {
    try {
      const result = await siteService.getWeb(id);
      let _site = result.data;

      props.form.setFields({
        contact: {
          value: _site.contact
        },
        email: {
          value: _site.email
        },
        country: {
          value: _site.country
        },
        medio: {
          value: _site.medio
        }
      });

      populate(_site.to.items);

      setSite(_site);
    } catch (err) {
      console.log(err);

      message.error(err.data.message);
    }
  };

  const { getFieldDecorator } = props.form;

  return (
    <>
      <PageHeader
        onBack={() => null}
        title="Agregar sitio"
        backIcon={null}
        subTitle="Agregar sitio para enviar por correo"
      />

      <Form onSubmit={handleSubmit} className="login-form" autoComplete="off">
        <Row gutter={16}>
          <Col md={8}>
            <Form.Item required label="Pais" style={{ marginBottom: 0 }} />
            <Form.Item>
              {getFieldDecorator("country", {
                rules: [
                  { required: true, message: "El campo Pais es requerido" }
                ]
              })(
                <Select
                  disabled={true}
                  loading={loading}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione un pais"
                >
                  {countries.map(country => (
                    <Select.Option key={country.id} value={country.id}>
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item required label="Correo" style={{ marginBottom: 0 }} />
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "El campo no es correo valido"
                  },
                  { required: true, message: "El campo Correo es requerido" }
                ]
              })(
                <Input
                  disabled={true}
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Correo"
                />
              )}
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item required label="Contacto" style={{ marginBottom: 0 }} />
            <Form.Item>
              {getFieldDecorator("contact", {
                rules: [
                  { required: true, message: "El campo Contacto es requerido" }
                ]
              })(
                <Input
                  disabled={true}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Contacto"
                />
              )}
            </Form.Item>
          </Col>

          <Col md={6}>
            <Form.Item label="Archivo CSV" extra="" style={{ marginBottom: 4 }}>
              <Upload
                disabled={true}
                name="file"
                beforeUpload={beforeUpload}
                onRemove={onRemove}
                fileList={fileList}
                accept={"text/*"}
              >
                <Button disabled={true}>
                  <Icon type="upload" /> Clic para subir csv
                </Button>
              </Upload>
              {site && !fileList.length && site.file}
            </Form.Item>
          </Col>

          <Col md={18}>
            <Form.Item required label="Medio" style={{ marginBottom: 4 }} />
            <Form.Item>
              {getFieldDecorator("medio", {
                rules: [
                  { required: true, message: "El campo Medio es requerido" }
                ]
              })(
                <Input.TextArea
                  disabled={true}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Medio"
                />
              )}
            </Form.Item>
          </Col>

          <Col md={24}>
            <Table columns={columns} dataSource={dataCSV} />
            <Divider />
          </Col>
        </Row>

        <Form.Item className="text-center">
          {/* <Button type="primary" htmlType="submit" size="large">
            Editar
          </Button> */}
          <Link to="/interconecting/listar">Mostrar todos</Link>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create({ name: "interconectingshow_form" })(
  InterconectingShowForm
);
