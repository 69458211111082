import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Table, Divider, PageHeader, Popconfirm, Col, Row, Button} from "antd";
import * as metricService from  "../../api/services/metric.service";

const Page = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        loadMetrics();

    }, []);

    const loadMetrics = async () => {
        try {
            const result = await metricService.list();
            populate(result.data);
        } catch (err) {
            console.log(err);
        }
    };

    const onConfirm = async id => {
        await metricService.remove(id);
        loadMetrics();
    };

    const columns = [
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Acción",
            key: "action",
            render: (text, record) => (
                <span>
                    <Link to={`/metrics/edit/${record.key}`}>Editar</Link>
                    <Divider type="vertical"/>

                    <Popconfirm
                        title="Esta seguro?"
                        okText="Sí"
                        cancelText="No"
                        onConfirm={() => onConfirm(record.key)}
                    >
                        <a>Eliminar</a>
                    </Popconfirm>
                </span>
            )
        }
    ];

    const populate = list => {
        let items = [];

        list.items.map(item => {
            items.push({
                key: item.id,
                name: item.name
            });
        });

        setData(items);
    };

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Listado de Métricas"
                backIcon={null}
            />
            <Row gutter={16}>
                <Col md={24}>
                    <Link to="/metrics/create" style={{ float: 'right' }}>
                    <Button type="primary">
                        Crear métrica
                    </Button>
                    </Link>
                </Col>
                <Col md={24}>
                    <Table columns={columns} dataSource={data} />
                </Col>
            </Row>
        </>
    );
};

export default Page;
