import React from "react";
import { withRouter } from "react-router-dom";
import CreateTweetForm from "./create-tweet-form";



const SendTweetPage = ({ match }) => {
    return (
        <>
            <CreateTweetForm />
        </>
    );
};

export default withRouter(SendTweetPage);