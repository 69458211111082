import React from "react";
import { withRouter } from "react-router-dom";
import MetricCreateForm from "../../components/forms/MetricCreateForm";

const Page = () => {
    return (
        <>
            <MetricCreateForm/>
        </>
    );
};

export default withRouter(Page);