import React, { useState, useEffect } from "react";
import { Form, Icon, Input, Button, Col, Row, PageHeader, message } from "antd";
import * as authService from "../api/services/auth.service";
import token from "../api/token";

const LoginForm = props => {
  const [loading, setLoading] = useState(false);
  const tok = token();

  useEffect(() => {
    if (tok) props.history.push("/");
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        login(values);
      }
    });
  };

  const login = async values => {
    setLoading(true);
    try {
      const result = await authService.login(values);
      localStorage.setItem(
        "session",
        JSON.stringify({
          token: result.data.token,
          rol: result.data.rol,
          id: result.data.id
        })
      );
      setLoading(false);
      window.location.href = "/";
    } catch (err) {
      message.error(err.data.message);
      setLoading(false);
    } finally {
    }
  };

  const { getFieldDecorator } = props.form;

  if (!token) return null;

  return (
    <>
      <PageHeader onBack={() => null} title="Login" backIcon={null} />

      <Row type="flex" justify="center">
        <Col span={6}>
          <Form
            onSubmit={handleSubmit}
            className="login-form"
            autoComplete="off"
          >
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "El campo Correo es requerido" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Correo"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "El campo Password es requerido" }
                ]
              })(
                <Input.Password
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Acceder
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Form.create({ name: "login_form" })(LoginForm);
