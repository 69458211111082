import Cliente from "../Cliente";

export const getNotifications = () => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}dashboard/notifications`);
};

export const getEmailsRead = () => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}dashboard/emails_read`);
};

export const getSentEmailsReport = (params = {}) => {
  return Cliente.setBearerToken()
      .getCliente()
      .get(`${process.env.REACT_APP_API_URL}dashboard/emails/sent`, {params: params});
};

export const getReceivedEmailsReport = (params = {}) => {
  return Cliente.setBearerToken()
      .getCliente()
      .get(`${process.env.REACT_APP_API_URL}dashboard/emails/received`, {params: params});
};

export const getCampaignsReport = (params = {}) => {
  return Cliente.setBearerToken()
      .getCliente()
      .get(`${process.env.REACT_APP_API_URL}dashboard/campaigns`, {params: params});
};