import React, {useEffect, useState} from "react";
import { withRouter } from "react-router-dom";
import {Button, Col, Icon, Input, message, PageHeader, Row, Table, Divider, Spin} from "antd";
import moment from "moment";
import * as mailingService from "../../api/services/mailing.service";

const Page = ({ match }) => {
    const [emailTable, setEmailTable] = useState({
        data: [],
        pagination: {
            pageSize: 20
        },
        loading: false
    });

    const [webId, setWebId] = useState();
    const [spinState, setSpinState] = useState(false);
    const [searchInput, setSearchInput] = useState(null);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        setSearchInput(node);
                    }}
                    placeholder={'Entre un valor'}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        }
    });

    const columns = [
        {
            title: "Domain",
            dataIndex: "domain",
            key: "domain",
            ...getColumnSearchProps('domain'),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: 250,
            ...getColumnSearchProps('email'),
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status"
        },
        {
            title: "Fecha de Envio",
            dataIndex: "date",
            key: "date"
        },
        {
            title: "Acción",
            key: "action",
            render: (text, record) =>
                (<div>
                    {
                        record._status === 'processing' &&
                            <Button type="primary" icon="pause" size="small"
                                    onClick={() => handleStopEmails(record.key)}>
                                Cancelar
                            </Button>
                    }
                    {
                        record._status === 'processing' && record.contact_id !== null &&
                        <Divider type="vertical" />
                    }
                    {
                        record.contact_id !== null &&
                            <Button type="primary" icon="import" size="small"
                                    onClick={() => handleImportContact(record.key)}>
                                Importar
                            </Button>
                    }
                </div> ),
        }
    ];

    useEffect(() => {
        setWebId(match.params.id);
        getEmails();
    }, []);


    const handleStopEmails = async (emailId) => {
        try {
            setSpinState(true);

            const result = await mailingService.stopSendingEmail(webId, {
                emailId:emailId,
            });

            setSpinState(false);

            if(result.data.success) {
                message.success("Los envíos fueron cancelados");
                await getEmails();
            } else {
                message.warning("El contacto no fue encontrado");
            }
        } catch (e) {
            setSpinState(false);
            message.error("No se pudieron cancelar los envíos");
        }
    };

    const handleImportContact = async (emailId) => {
        try {
            setSpinState(true);

            const result = await mailingService.importContact(webId, {
                emailId:emailId,
            });

            setSpinState(false);

            if(result.data.success) {
                message.success("El contacto fue importado satisfactoriamente");
                await getEmails();
            } else {
                message.warning("Este contacto ya había sido importado previamente");
            }
        } catch (e) {
            setSpinState(false);
            message.error("Ha ocurrido un error importando el contacto");
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const handleEmailTableChange = (pagination, filters, sorter) => {
        const pager = { ...emailTable.pagination };
        pager.current = pagination.current;

        setEmailTable({
            pagination: pager,
        });

        getEmails({
            limit: pagination.pageSize,
            page: pagination.current,
            ...filters
        });
    };

    const getEmails = async (params = {}) => {
        try {
            setEmailTable({
                loading: true
            });

            if(!params.limit){
                params.limit = 20;
            }
            if(!params.page){
                params.page = 1;
            }

            const result = await mailingService.get(match.params.id, params);

            let items = [];
            result.data.items.map(item => {
                let status = '';
                switch (item.status){
                    case 'sent':
                        status = 'Enviado';
                        break;
                    case 'stopped':
                    case 'cancelled':
                        status = 'Cancelado';
                        break;
                    case 'contacted':
                        status = 'Contactado';
                        break;
                    case 'confirmed':
                        status = 'Confirmado';
                        break;
                    case 'locked':
                        status = 'Bloqueado';
                        break;
                    case 'Bad':
                    case 'Unverifiable':
                    case 'RetryLater':
                        status = 'Email no verificado';
                        break;
                    case 'error':
                        status = 'Error de envío';
                        break;
                    default:
                        status = 'Procesando';
                }

                items.push({
                    key: item.id,
                    domain: (item.domain) ? item.domain: '-',
                    email: item.to,
                    name: (item.contact_name) ? item.contact_name : '-',
                    _status: item.status,
                    contact_id: (item.contact_id) ? item.contact_id : null,
                    status: status,
                    date: moment(item.date_send, "YYYY-MM-DDTHH:mm:ssZZ").format("YYYY-MM-DD"),
                });
            });

            const pagination = { ...emailTable.pagination };
            pagination.total = result.data.items_count;
            setEmailTable({
                loading: false,
                data: items,
                pagination,
            });
        } catch (e) {

        }
    };

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Emails"
                backIcon={null}
                subTitle="Listado de emails"
            />
            <Row gutter={16}>
                <Col md={24}>
                    <Spin spinning={spinState}>
                        <Table columns={columns}
                           dataSource={emailTable.data}
                           pagination={emailTable.pagination}
                           loading={emailTable.loading}
                           onChange={handleEmailTableChange}
                           />
                    </Spin>
                </Col>
            </Row>
        </>
    );
};

export default withRouter(Page);