import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Form,
  Icon,
  Input,
  Button,
  Select,
  Row,
  Col,
  PageHeader,
  Upload
} from "antd";
import Cliente from "../../api/Cliente";
import * as emailService from "../../api/services/outreacher_email.service";

const EmailForm = props => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState({});

  useEffect(() => {
    loadEmail();
  }, []);

  const loadEmail = async () => {
    try {
      const _email = await emailService.show(props.id);

      const { email } = _email.data;

      props.form.setFields({
        email: {
          value: email
        }
      });

      setEmail(_email.data);
    } catch (err) {}
  };

  const { getFieldDecorator } = props.form;

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        try {
          await Cliente.getCliente().put(
            `${process.env.REACT_APP_API_URL}outreacher-email/${props.id}`,
            {
              ...values
            }
          );

          props.history.push("/emails/listar");
        } catch (err) {
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <PageHeader onBack={() => null} title="Editar email" backIcon={null} />

      <Form onSubmit={handleSubmit} className="login-form" autoComplete="off">
        <Row gutter={16}>
          <Col md={8}>
            <Form.Item required label="Correo" style={{ marginBottom: 0 }} />
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "El campo no es correo valido"
                  },
                  { required: true, message: "El campo Correo es requerido" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Correo"
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="text-center">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
          >
            Editar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default withRouter(Form.create({ name: "email_form" })(EmailForm));
