import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    Form,
    Icon,
    Input,
    InputNumber,
    Button,
    Select,
    Row,
    Col,
    PageHeader,
    Switch,
    Upload,
    message,
    Table,
    Divider,
    AutoComplete,
    Tag,
    Tabs,
    Slider,
    message as Message
} from "antd";

import * as metricService from  "../../api/services/metric.service";

const MetricCreateForm = props => {

    const [submitting, setSubmit] = useState(false);
    const[ahrefsReferringDomainMinValue, setAhrefsReferringDomainMinValue] = useState(-1);
    const[ahrefsReferringDomainMaxValue, setAhrefsReferringDomainMaxValue] = useState(-1);
    const[ahrefsGovExternalLinksMinValue, setAhrefsGovExternalLinksMinValue] = useState(-1);
    const[ahrefsGovExternalLinksMaxValue, setAhrefsGovExternalLinksMaxValue] = useState(-1);
    const[ahrefsEduExternalLinksMinValue, setAhrefsEduExternalLinksMinValue] = useState(-1);
    const[ahrefsEduExternalLinksMaxValue, setAhrefsEduExternalLinksMaxValue] = useState(-1);
    const[ahrefsRefClassCMinValue, setAhrefsRefClassCMinValue] = useState(-1);
    const[ahrefsRefClassCMaxValue, setAhrefsRefClassCMaxValue] = useState(-1);
    const[ahrefsBacklinksMinValue, setAhrefsBacklinksMinValue] = useState(-1);
    const[ahrefsBacklinksMaxValue, setAhrefsBacklinksMaxValue] = useState(-1);
    const[ahrefsTrafficMinValue, setAhrefsTrafficMinValue] = useState(-1);
    const[ahrefsTrafficMaxValue, setAhrefsTrafficMaxValue] = useState(-1);
    const[semrushKeywordsMinValue, setSemrushKeywordsMinValue] = useState(-1);
    const[semrushKeywordsMaxValue, setSemrushKeywordsMaxValue] = useState(-1);
    const[semrushTrafficMinValue, setSemrushTrafficMinValue] = useState(-1);
    const[semrushTrafficMaxValue, setSemrushTrafficMaxValue] = useState(-1);

    const { TabPane } = Tabs;

    const marks = {
        0: '0',
        25: '25',
        50: '50',
        75: '75',
        100: {
            style: {
                color: '#f50',
            },
            label: <strong>100</strong>,
        },
    };

    const marks0_10 = {
        0: '0',
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: {
            style: {
                color: '#f50',
            },
            label: <strong>10</strong>,
        },
    };

    const marks0_17 = {
        0: '0',
        4: '4',
        9: '9',
        13: '13',
        17: {
            style: {
                color: '#f50',
            },
            label: <strong>17</strong>,
        },
    };

    const onChangeReferringDomainMin = value => {
        setAhrefsReferringDomainMinValue(value);
        props.form.setFields({
            ahrefs_refdomains: {
                value: [value, props.form.getFieldValue('ahrefs_refdomains')[1]]
            }
        });
    };

    const onChangeReferringDomainMax = value => {
        setAhrefsReferringDomainMaxValue(value);
        let minValue = props.form.getFieldValue('ahrefs_refdomains')[0];

        props.form.setFields({
            ahrefs_refdomains: {
                value: [minValue, value]
            }
        });
    };

    const onChangeGovExternalLinksMin = value => {
        setAhrefsGovExternalLinksMinValue(value);
        props.form.setFields({
            ahrefs_gov: {
                value: [value, props.form.getFieldValue('ahrefs_gov')[1]]
            }
        });
    };

    const onChangeGovExternalLinksMax = value => {
        setAhrefsGovExternalLinksMaxValue(value);
        let minValue = props.form.getFieldValue('ahrefs_gov')[0];

        props.form.setFields({
            ahrefs_gov: {
                value: [minValue, value]
            }
        });
    };

    const onChangeEduExternalLinksMin = value => {
        setAhrefsEduExternalLinksMinValue(value);
        props.form.setFields({
            ahrefs_edu: {
                value: [value, props.form.getFieldValue('ahrefs_edu')[1]]
            }
        });
    };

    const onChangeEduExternalLinksMax = value => {
        setAhrefsEduExternalLinksMaxValue(value);
        let minValue = props.form.getFieldValue('ahrefs_edu')[0];

        props.form.setFields({
            ahrefs_edu: {
                value: [minValue, value]
            }
        });
    };

    const onChangeRefClassCMin = value => {
        setAhrefsRefClassCMinValue(value);
        props.form.setFields({
            ahrefs_refclass_c: {
                value: [value, props.form.getFieldValue('ahrefs_refclass_c')[1]]
            }
        });
    };

    const onChangeRefClassCMax = value => {
        setAhrefsRefClassCMaxValue(value);
        let minValue = props.form.getFieldValue('ahrefs_refclass_c')[0];

        props.form.setFields({
            ahrefs_refclass_c: {
                value: [minValue, value]
            }
        });
    };

    const onChangeAhrefsBacklinksMin = value => {
        setAhrefsBacklinksMinValue(value);
        props.form.setFields({
            ahrefs_backlinks: {
                value: [value, props.form.getFieldValue('ahrefs_backlinks')[1]]
            }
        });
    };
    const onChangeAhrefsBacklinksMax = value => {
        setAhrefsBacklinksMaxValue(value);
        let minValue = props.form.getFieldValue('ahrefs_backlinks')[0];

        props.form.setFields({
            ahrefs_backlinks: {
                value: [minValue, value]
            }
        });
    };
    const onChangeAhrefsTrafficMin = value => {
        setAhrefsTrafficMinValue(value);
        props.form.setFields({
            ahrefs_traffic: {
                value: [value, props.form.getFieldValue('ahrefs_traffic')[1]]
            }
        });
    };
    const onChangeAhrefsTrafficMax = value => {
        setAhrefsTrafficMaxValue(value);
        let minValue = props.form.getFieldValue('ahrefs_traffic')[0];

        props.form.setFields({
            ahrefs_traffic: {
                value: [minValue, value]
            }
        });
    };

    const onChangeSemrushKeywordsMin = value => {
        setSemrushKeywordsMinValue(value);
        props.form.setFields({
            sem_or: {
                value: [value, props.form.getFieldValue('sem_or')[1]]
            }
        });
    };

    const onChangeSemrushKeywordsMax = value => {
        setSemrushKeywordsMaxValue(value);
        let minValue = props.form.getFieldValue('sem_or')[0];

        props.form.setFields({
            sem_or: {
                value: [minValue, value]
            }
        });
    };

    const onChangeSemrushTrafficMin = value => {
        setSemrushTrafficMinValue(value);
        props.form.setFields({
            sem_ot: {
                value: [value, props.form.getFieldValue('sem_ot')[1]]
            }
        });
    };

    const onChangeSemrushTrafficMax = value => {
        setSemrushTrafficMaxValue(value);
        let minValue = props.form.getFieldValue('sem_ot')[0];

        props.form.setFields({
            sem_ot: {
                value: [minValue, value]
            }
        });
    };

    const validateMetrics = () => {
        let messages=[];

        if (ahrefsReferringDomainMinValue > ahrefsReferringDomainMaxValue) {
            messages.push('El valor mínimo de Referring Domains debe ser mayor que el valor máximo');
        }
        if(ahrefsGovExternalLinksMinValue > ahrefsGovExternalLinksMaxValue) {
            messages.push('El valor mínimo de Gov External Links debe ser mayor que el valor máximo');
        }
        if(ahrefsEduExternalLinksMinValue > ahrefsEduExternalLinksMaxValue) {
            messages.push('El valor mínimo de Edu External Links debe ser mayor que el valor máximo');
        }
        if(ahrefsRefClassCMinValue > ahrefsRefClassCMaxValue) {
            messages.push('El valor mínimo de Ref Class C debe ser mayor que el valor máximo');
        }
        if(ahrefsBacklinksMinValue > ahrefsBacklinksMaxValue) {
            messages.push('El valor mínimo de Backlinks debe ser mayor que el valor máximo');
        }
        if(ahrefsTrafficMinValue > ahrefsTrafficMaxValue) {
            messages.push('El valor mínimo de Traffic debe ser mayor que el valor máximo');
        }
        if(semrushKeywordsMinValue > semrushKeywordsMaxValue) {
            messages.push('El valor mínimo de SEMrush Keywords debe ser mayor que el valor máximo');
        }
        if(semrushTrafficMinValue > semrushTrafficMaxValue) {
            messages.push('El valor mínimo de SEMrush Traffic debe ser mayor que el valor máximo');
        }

        if(messages.length == 0){
            return true;
        } else {
            let idx = 1;
            let warningMsg="Algunas métricas no están configuradas corectamente:";
            messages.map(msg => {
                warningMsg = `${warningMsg} ${idx}- ${msg}.`;
                idx++;
            });

            message.warning(warningMsg);

            return false;
        }

    }

    const handleSubmit = e => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            if (!err) {
                if (validateMetrics()) {
                    setSubmit(true);
                    await metricService.create(values);
                    props.history.push("/metrics/list");
                }
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Agregar Métrica"
                backIcon={null}
            />
            <Form onSubmit={handleSubmit} className="add-metric-form" autoComplete="off">
                <Row gutter={16}>
                    <Col md={6}>
                        <Form.Item required label="Nombre" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("name", {
                                rules: [
                                    { required: true, message: "El campo Nombre es requerido" }
                                ]
                            })(
                                <Input

                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                    <Row gutter={16}>
                        <Col md={24}>
                            <Form.Item label="Métricas" style={{ marginBottom: 0 }} />
                            <Form.Item>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="MOZ" key="moz" style={{padding: '10px 20px'}}>
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Form.Item label="Moz Domain Authority" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_pda", {
                                                    initialValue: [15, 65],
                                                })(
                                                    <Slider range marks={marks} min={0} max={100} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Moz Homepage Authority" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_upa", {
                                                    initialValue: [15, 65],
                                                })(
                                                    <Slider range min={0} max={100} marks={marks} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Moz Homepage MozRank" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_umrp", {
                                                    initialValue: [1, 7],
                                                })(
                                                    <Slider range min={0} max={10} marks={marks0_10} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Moz Subdomain MozRank" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_fmrp", {
                                                    initialValue: [1, 7],
                                                })(
                                                    <Slider range min={0} max={10} marks={marks0_10} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Moz Spam Score" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_fspsc", {
                                                    initialValue: [1, 17],
                                                })(
                                                    <Slider range min={0} max={17} marks={marks0_17} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="Majestic" key="majestic">
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Form.Item label="Majestic Citation Flow" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("majestic_citation_flow", {
                                                    initialValue: [-1, -1],
                                                })(
                                                    <Slider range marks={marks} min={0} max={100} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Majestic Trust Flow" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("majestic_trust_flow", {
                                                    initialValue: [-1, -1],
                                                })(
                                                    <Slider range min={0} max={100} marks={marks} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="ahrefs" key="ahrefs">
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Form.Item label="Domain Rating" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("ahrefs_domain_rating", {
                                                    initialValue: [-1, -1],
                                                })(
                                                    <Slider range marks={marks} min={0} max={100}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={32}>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Referring Domains" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsReferringDomainMinValue}
                                                        onChange={onChangeReferringDomainMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsReferringDomainMaxValue}
                                                        onChange={onChangeReferringDomainMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Gov External Links" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsGovExternalLinksMinValue}
                                                        onChange={onChangeGovExternalLinksMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsGovExternalLinksMaxValue}
                                                        onChange={onChangeGovExternalLinksMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Edu External Links" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsEduExternalLinksMinValue}
                                                        onChange={onChangeEduExternalLinksMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsEduExternalLinksMaxValue}
                                                        onChange={onChangeEduExternalLinksMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Ref Class C" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsRefClassCMinValue}
                                                        onChange={onChangeRefClassCMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsRefClassCMaxValue}
                                                        onChange={onChangeRefClassCMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Backlinks" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsBacklinksMinValue}
                                                        onChange={onChangeAhrefsBacklinksMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsBacklinksMaxValue}
                                                        onChange={onChangeAhrefsBacklinksMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Traffic" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsTrafficMinValue}
                                                        onChange={onChangeAhrefsTrafficMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsTrafficMaxValue}
                                                        onChange={onChangeAhrefsTrafficMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="SEMrush" key="semrush">
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="SEMrush Keywords" style={{marginBottom: 0}}/>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{marginBottom: 0}}/>
                                                    <InputNumber
                                                        min={-1}
                                                        value={semrushKeywordsMinValue}
                                                        onChange={onChangeSemrushKeywordsMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{marginBottom: 0}}/>
                                                    <InputNumber
                                                        min={-1}
                                                        value={semrushKeywordsMaxValue}
                                                        onChange={onChangeSemrushKeywordsMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="SEMrush Traffic" style={{marginBottom: 0}}/>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{marginBottom: 0}}/>
                                                    <InputNumber
                                                        min={-1}
                                                        value={semrushTrafficMinValue}
                                                        onChange={onChangeSemrushTrafficMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{marginBottom: 0}}/>
                                                    <InputNumber
                                                        min={-1}
                                                        value={semrushTrafficMaxValue}
                                                        onChange={onChangeSemrushTrafficMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="Google" key="google">
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Form.Item label="PageRank" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("google_pr", {
                                                    initialValue: [-1, -1],
                                                })(
                                                    <Slider range marks={marks0_10} min={0} max={10}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                            </Form.Item>
                        </Col>
                    </Row>
                <Form.Item className="text-center">
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={submitting}
                    >
                        Crear
                    </Button>
                </Form.Item>
                {getFieldDecorator("ahrefs_refdomains", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_gov", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_edu", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_refclass_c", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_backlinks", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_traffic", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("sem_or", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("sem_ot", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
            </Form>
        </>
    );
}

export default withRouter(
    Form.create({ name: "add-metric-form" })(MetricCreateForm)
);