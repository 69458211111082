import React, { useEffect, useState } from "react";
import {Link, withRouter} from "react-router-dom";
import {Col, Row, Comment, Icon, Tooltip, PageHeader, Spin} from "antd";
import * as mailingService from  "../../api/services/mailing.service";
import moment, {now} from "moment";
import ReplyEmailForm from "../../components/forms/ReplyEmailForm";

const InboundEmailPage = ({ match }) => {

    const [email, setEmail] = useState({
        from_name: "",
        from_email:"",
        subject:"",
        message_preview:"",
        created_at: moment(),
    });

   const [spinState, setSpinState] = useState(true);
   const [reply, setReply] = useState(false);

    useEffect(() => {
        getInbox();
    }, []);

    const hideReplyForm = () => {
        setReply(false);
    }

    const showReplyForm = () => {
        setReply(true);
    };

    const getInbox = async (params = {}) => {
        try {

            const result = await mailingService.getInboundEmail(match.params.id);
            if (result.data) {
                setEmail(result.data);
                setSpinState(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

     const actions = [
         <span key="comment-basic-reply-to">
                 <Icon style={{ fontSize: 16 }}
                     type="rollback"
                     onClick={showReplyForm}
                 />
             <span style={{ paddingLeft: 8 }} onClick={showReplyForm}>Responder</span>
         </span>
        ];

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Detalles"
                backIcon={null}
            />
            <Spin spinning={spinState}>
            <Row gutter={16}>
                <Col md={24}>
                    <Comment
                        actions={actions}
                        author={<a>{email.from_name}</a>}
                        content={
                            <div>
                            <p>
                                {email.subject}
                            </p>
                            <p>
                                {email.message_preview}
                            </p>
                            </div>
                        }
                        datetime={
                            <Tooltip title={moment(email.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(email.created_at).fromNow()}</span>
                            </Tooltip>
                        }
                    />
                </Col>
            </Row>

            { reply === true &&
                <ReplyEmailForm data={email} onCancel={hideReplyForm}/>
            }
            </Spin>
        </>
    );
};
export default withRouter(InboundEmailPage);