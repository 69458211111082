import Cliente from "../Cliente";

export const getContacts = (params = {}) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}contacts/`,{params: params});
};

export const getContactsAndOpportunities = (params = {}) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}contacts/contacts-opportunities/`,{params: params});
};

export const getConfirmedContacts = (params = {}) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}contacts/confirmed-contacts`,{params: params});
};

export const sendEmails = (values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}contacts/send-emails`, {
            values
        });
};

export const sendSimpleEmail = (values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}contacts/send-simple-email`, values);
};

export const importContact = (values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}contacts/import`, {
            values
        });
};

export const exportContacts = (values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}contacts/export`, {
            values
        }, {
            responseType: 'blob'
        });
};