import React from "react";
import { Result, Button } from "antd";

const Page = () => {
    return (
        <Result
            status="403"
            title="Página en construcción"
        />
    );
};

export default Page;