import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Divider, Popconfirm } from "antd";
import * as userService from "../../api/services/user.service";
import token from "../../api/token";

const Page = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    loadWebs();
  }, []);

  const loadWebs = async () => {
    try {
      const result = await userService.getAll();
      populate(result.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onConfirm = async id => {
    await userService.destroy(id);

    loadWebs();
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Apellidos",
      dataIndex: "lastname",
      key: "lastname"
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Accion",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/usuarios/editar/${record.key}`}>Editar</Link>
          <Divider type="vertical" />

          {token().id !== record.key && (
            <Popconfirm
              title="Esta seguro?？"
              okText="Sí"
              cancelText="No"
              onConfirm={() => onConfirm(record.key)}
            >
              <a>Eliminar</a>
            </Popconfirm>
          )}
        </span>
      )
    }
  ];

  const populate = _data => {
    let d = [];

    _data.map(item => {
      d.push({
        key: item.id,
        name: item.name,
        lastname: item.lastname,
        email: item.email
      });
    });

    setData(d);
  };

  return (
    <>
      <Table columns={columns} dataSource={data} />
    </>
  );
};

export default Page;
