import React from "react";
import { withRouter } from "react-router-dom";
import UsuarioFormEdit from "../../components/forms/UsuarioFormEdit";

const Page = ({ match }) => {
  return (
    <>
      <UsuarioFormEdit id={match.params.id} />
    </>
  );
};

export default withRouter(Page);
