import React, { useEffect, useState, useRef } from "react";
import {Link, withRouter} from "react-router-dom";
import {
    Form,
    Icon,
    Input,
    Button,
    Row,
    Col,
    message,
    Table,
    AutoComplete,
    Select, Typography,
    Spin, Tag, Card,
} from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactFlagsSelect from 'react-flags-select';

import * as contactService from  "../../api/services/contact.service";
import * as outreacherEmailService from "../../api/services/outreacher_email.service";
import * as messageService from "../../api/services/message.service";
import * as opportunityService from "../../api/services/opportunity.service";
import * as templateService from  "../../api/services/template.service";

const CampaignEmailForm = props => {
    const [submitting, setSubmit] = useState(false);
    const [spinState, setSpinState] = useState(false);
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [outreachEmails, setOutreachEmails] = useState([]);
    const [contactTable, setContactTable] = useState({
        data: [],
        loading: false,
    });
    const [downloading, setDownloading] = useState(false);
    const [tLDFilterType, setTLDFilterType] = useState([]);
    const [topLevelDomains, setTopLevelDomains] = useState([]);

    const [isoLanguages, setIsoLanguages] = useState([]);
    const [languages, setLanguages] = useState({});
    const [defaultLanguage, setDefaultLanguage] = useState("");

    let [emailsFilter, setEmailsFilter] = useState([]);
    const [inputValue, setInputValue]= useState("");
    const [inputVisible, setInputVisible]= useState(false);

    const editorModules = {
        toolbar: [
            [{ 'size': ['small', 'normal', 'large'] }],
            [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
        ],
    };

    const editorFormats = [
        'size',
        'header',
        'font',
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent',
        'link'
    ];

    let _emails = [];

    const columns = [
        {
            title: "WEB",
            dataIndex: "web",
            key: "web"
        },
        {
            title: "NOMBRE",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "APELLIDOS",
            dataIndex: "lastname",
            key: "lastname"
        },
        {
            title: "CORREO ELECTRÓNICO",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "ACCIÓN",
            key: "action",
            render: (text, record) => (
                <Button type="primary" icon="import" size="small"
                        onClick={() => handleImportContact(record.key, record.webId, record.email)}>
                    Importar
                </Button>
            )
        }
    ];

    const { Text } = Typography;

    useEffect(() => {
        (async () => {
            try {
                setDefaultLanguage("US");
                loadOutreacherEmail();
                getLanguages();
                getTemplates();
                getContacts();
                getMessages(defaultLanguage);
                getTLDFilterType();
                getTLDList();
            } catch (err) {}
        })();
    }, []);

    const getTemplates = async () => {
        let list = [];
        const templatesResponse = await templateService.list();

        if (templatesResponse.data.Data) {
            let list = [];
            templatesResponse.data.Data.map(item => {
                list.push({
                    id: item.ID,
                    name: item.Name
                });
            });

            setTemplates(list);
            setLoading(false);
        }
    }

    const getLanguages = async () => {
        setIsoLanguages(["US", "ES", "DE", "FR", "IT"]);
        setLanguages({"US":"Inglés", "ES": "Español", "DE": "Alemán", "FR": "Francés", "IT": "Italiano"});
    }

    const handleLanguageChange = e => {
        setDefaultLanguage(e);
        if(e === 'US') {
            getMessages('en');
        } else {
            getMessages(e);
        }
    };

    const getContacts = async (params = {}) => {
        setContactTable({
            loading: true
        });
        params.campaign = props.id;

        // const data =  await contactService.getContactsAndOpportunities(params);
        const data = await opportunityService.getOpportunitiesAndContacts(params);

        let items = [];

        data.data.items.map(item => {
            items.push({
                key: item.id,
                name: item.first_name,
                lastname: item.last_name,
                web: item.opportunities[0].domain.name,
                email: item.email,
                webId: item.opportunities[0].id
            });
        });

        setContactTable({
            loading: false,
            data: items,
        });
    }

    const loadOutreacherEmail = async () => {
        try {
            const result = await outreacherEmailService.list();
            let emails = [];

            result.data.map(outreach => {
                emails.push(outreach.email);
            });

            _emails = [...emails];

            props.form.setFields({
                email: {
                    value: _emails[0]
                }
            });

            setOutreachEmails(emails);
        } catch (e) {
        }
    };

    const getMessages = async (locale) => {
        const result = await messageService.list({"locale": locale.toLowerCase()});

        const messages = result.data;

        props.form.setFields({
            message1: {
                value: messages && messages.message1 ? messages.message1 : ""
            },
            message2: {
                value: messages && messages.message2 ? messages.message2 : ""
            },
            message3: {
                value: messages && messages.message3 ? messages.message3 : ""
            },
            message4: {
                value: messages && messages.message4 ? messages.message4 : ""
            },
            subject1: {
                value: messages && messages.subject1 ? messages.subject1 : ""
            },
            subject2: {
                value: messages && messages.subject2 ? messages.subject2 : ""
            },
            subject3: {
                value: messages && messages.subject3 ? messages.subject3 : ""
            },
            subject4: {
                value: messages && messages.subject4 ? messages.subject4 : ""
            }
        });
    };

    const getTLDFilterType = () => {
        const filterType = [
            {
                id: 'include',
                name: 'Incluir'
            }, {
                id: 'exclude',
                name: 'Excluir'
            }
        ]
        setTLDFilterType(filterType);
    }

    const getTLDList = () => {
        const tld = [
            {name: '.com'}, {name: '.org'}, {name: '.int'}, {name: '.edu'},
            {name: '.gov'}, {name: '.mil'}, {name: '.ad'},  {name: '.ae'},
            {name: '.af'},  {name: '.ag'},  {name: '.ao'},  {name: '.ar'},
            {name: '.at'},  {name: '.au'},  {name: '.aw'},  {name: '.ax'},
            {name: '.bb'},  {name: '.be'},  {name: '.bn'},  {name: '.bo'},
            {name: '.br'},  {name: '.bs'},  {name: '.ca'},  {name: '.cd'},
            {name: '.cl'},  {name: '.cm'},  {name: '.co'},  {name: '.cr'},
            {name: '.cu'},  {name: '.cz'},  {name: '.de'},  {name: '.dk'},
            {name: '.ec'},  {name: '.ee'},  {name: '.eg'},  {name: '.es'},
            {name: '.eu'},  {name: '.fi'},  {name: '.fr'},  {name: '.ge'},
            {name: '.gf'},  {name: '.gr'},  {name: '.gt'},  {name: '.gw'},
            {name: '.gy'},  {name: '.hk'},  {name: '.hn'},  {name: '.hr'},
            {name: '.hu'},  {name: '.id'},  {name: '.ie'},  {name: '.il'},
            {name: '.in'},  {name: '.ir'},  {name: '.it'},  {name: '.jm'},
            {name: '.jp'},  {name: '.kp'},  {name: '.kr'},  {name: '.mx'},
            {name: '.ni'},  {name: '.nl'},  {name: '.nz'},  {name: '.pa'},
            {name: '.pe'},  {name: '.pl'},  {name: '.pr'},  {name: '.pt'},
            {name: '.py'},  {name: '.qa'},  {name: '.ro'},  {name: '.rs'},
            {name: '.ru'},  {name: '.sa'},  {name: '.se'},  {name: '.sg'},
            {name: '.tr'},  {name: '.uk'},  {name: '.us'},  {name: '.uy'},
            {name: '.ve'},  {name: '.vn'},  {name: '.za'},  {name: '.my'},
            {name: '.no'},  {name: '.ph'},  {name: '.ch'},  {name: '.th'},
            {name: '.ua'}
        ];

        setTopLevelDomains(tld);
    }

    const handleClose = removedTag => {
        const tags = emailsFilter.filter(tag => tag !== removedTag);
        setEmailsFilter(tags);
        props.form.setFieldsValue({
            contact_email: tags.join(","),
        });
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = e => {
        setInputValue((e.target.value));
    };

    const handleInputConfirm = () => {
        if (inputValue && emailsFilter.indexOf(inputValue) === -1) {
            emailsFilter = [...emailsFilter, inputValue];
        }
        setEmailsFilter(emailsFilter);
        setInputVisible(false);
        setInputValue('');
        props.form.setFieldsValue({
            contact_email: emailsFilter.join(","),
        });
    };

    const handleFilter = e => {
        const values = {
            filter_tld_type: props.form.getFieldValue('tld_filter_type'),
            filter_tld: props.form.getFieldValue('tld'),
            filter_op_domain: props.form.getFieldValue('op_domain'),
            filter_email_type: props.form.getFieldValue('email_filter_type'),
            filter_email: props.form.getFieldValue('contact_email')
        }

        getContacts(values);
    };

    const handleReloadData = async () => {
        props.form.setFields({
            tld_filter_type: {
                value: ""
            },
            tld: {
                value: []
            },
            op_domain: {
                value: ""
            },
            email_filter_type: {
                value: ""
            },
            contact_email: {
                value: []
            },
        });
        setEmailsFilter([]);
        await getContacts();
    }

    const handleExport = async () => {
        setDownloading(true);

        const values = {
            campaignId: props.id,
            filter_tld_type: props.form.getFieldValue('tld_filter_type'),
            filter_tld: props.form.getFieldValue('tld'),
            filter_op_domain: props.form.getFieldValue('op_domain'),
            filter_email_type: props.form.getFieldValue('email_filter_type'),
            filter_email: props.form.getFieldValue('contact_email')
        };

        const response = await contactService.exportContacts(values);

        const fileName = (response.headers["filename"]) ? response.headers["filename"] : "Campaign.csv";

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setDownloading(false);
    };

    const handleImportContact = async (contactId, webId, email) => {
        try {
            setSpinState(true);

            const result = await contactService.importContact({
                contactId:contactId,
                email: email,
                webId: webId,
                campaignId: props.id
            });

            setSpinState(false);

            if(result.data.imported) {
                message.success("El contacto fue importado satisfactoriamente");
            } else {
                message.warning("Este contacto ya había sido importado previamente");
            }
        } catch (e) {
            setSpinState(false);
            message.error("Ha ocurrido un error importando el contacto");
        }
    };

    const validateMessages = () => {
        if(!props.form.getFieldValue('message1').replace(/<[^>]*>/g, "").trim()) {
            props.form.setFields({
                message1: {
                    value: ""
                },
            })
        }
        if(!props.form.getFieldValue('message2').replace(/<[^>]*>/g, "")) {
            props.form.setFields({
                message2: {
                    value: ""
                },
            })
        }
        if(!props.form.getFieldValue('message3').replace(/<[^>]*>/g, "").trim()) {
            props.form.setFields({
                message3: {
                    value: ""
                },
            })
        }
        if(!props.form.getFieldValue('message4').replace(/<[^>]*>/g, "").trim()) {
            props.form.setFields({
                message4: {
                    value: ""
                },
            })
        }
    };

    const handleSubmit = e => {
        e.preventDefault();

        validateMessages();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                setSubmit(true);
                setSpinState(true);
                try {

                    values.filter_tld_type = props.form.getFieldValue('tld_filter_type');
                    values.filter_tld = props.form.getFieldValue('tld');
                    values.filter_op_domain = props.form.getFieldValue('op_domain');
                    values.filter_email_type = props.form.getFieldValue('email_filter_type');
                    values.filter_email = props.form.getFieldValue('contact_email');
                    let locale = defaultLanguage.toLowerCase();
                    if(locale === 'us'){
                        locale = 'en';
                    }
                    values.locale = locale;

                    await contactService.sendEmails(values);
                    setSubmit(false);
                    setSpinState(false);
                    message.success("Los emails fueron configurados y comenzarán a enviarse en unos segundos");
                    props.history.push("/campaigns/list");
                } catch (e) {
                    setSubmit(false);
                    setSpinState(false);
                    message.error("Ha ocurrido un error procesando los contactos");
                }
            } else {
                message.warning("Por favor, compruebe que todos los campos estén correctos");
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
            <Form onSubmit={handleSubmit} autoComplete="off">
                <Row gutter={16}>
                    <Col md={8}>
                        <Form.Item
                            required
                            label="Correo de origen"
                            style={{ marginBottom: 0 }}
                        />
                        <Form.Item>
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        type: "email",
                                        message: "El campo no es un correo valido"
                                    },
                                    {
                                        required: true,
                                        message: "El campo Correo de origen es requerido"
                                    }
                                ]
                            })(
                                <AutoComplete className={"auto-complete-blue-v1"}
                                    dataSource={outreachEmails}
                                    filterOption={(inputValue, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(inputValue.toLowerCase()) !== -1
                                    }
                                    prefix={
                                        <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                                    }
                                    placeholder="Correo de origen"
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={8}>
                        <Form.Item required label="Plantilla" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("template", {
                                rules: [
                                    { required: true, message: "El campo Plantilla es requerido" }
                                ]
                            })(
                                <Select className={"select-red-v1"}
                                    loading={loading}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione una plantilla"
                                >
                                    {templates.map(template => (
                                        <Select.Option key={template.id} value={template.id}>
                                            {template.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={24} stye={{marginBottom:"20px"}}>
                        <div className="d-flex justify-content-between">
                            <h4>Mensajes</h4>
                            <ReactFlagsSelect className="i-language-selector float-right"
                                selected={defaultLanguage}
                                onSelect={handleLanguageChange}
                                countries={isoLanguages}
                                customLabels={languages}
                                placeholder="Seleccione un Idioma"
                            />
                        </div>
                    </Col>
                    <Col md={24}>
                        <div className="i-tagline i-tagline-bl d-flex">
                            <Text strong style={{marginRight: "10px" }} >Nota:</Text>
                            <p style={{marginBottom: 0}}>
                                <Text style={{color: "#99979c", fontStyle: "italic"}}>
                                    Puedes agregar a los mensajes variables de reemplazo que se actualizarán al enviar
                                    el correo.
                                    Las variables disponibles son <Text strong>{"{{name}}"}</Text>, <Text strong>{"{{domain}}"}</Text> y
                                    <Text strong> {"{{contact_name}}"}</Text> .
                                </Text>
                            </p>
                        </div>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item required label="Mensaje 1" style={{ marginBottom: 0 }} />

                        <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator("subject1", {
                                rules: [
                                    { required: true, message: "El campo es requerido" }
                                ]
                            })(
                                <Input
                                    prefix={
                                        <Icon type="message" style={{ color: "rgba(0,0,0,.25)" }} />
                                    }
                                    placeholder="Asunto"
                                />
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator("message1", {
                                rules: [
                                    { required: true, message: "El campo es requerido"}
                                ],
                                initialValue: ''
                            })(
                                <ReactQuill theme="snow" modules={editorModules} formats={editorFormats}
                                            className="custom-ql-editor"/>
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={12}>
                        <Form.Item label="Mensaje 2" style={{ marginBottom: 0 }} />

                        <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator("subject2", {
                            })(
                                <Input
                                    prefix={
                                        <Icon type="message" style={{ color: "rgba(0,0,0,.25)" }} />
                                    }
                                    placeholder="Asunto"
                                />
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator("message2", {
                                initialValue: '',
                            })(
                                <ReactQuill theme="snow" modules={editorModules} formats={editorFormats}
                                            className="custom-ql-editor"/>
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={12}>
                        <Form.Item label="Mensaje 3" style={{ marginBottom: 0}} />

                        <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator("subject3", {
                            })(
                                <Input
                                    prefix={
                                        <Icon type="message" style={{ color: "rgba(0,0,0,.25)" }} />
                                    }
                                    placeholder="Asunto"
                                />
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator("message3", {
                                initialValue: ''
                            })(
                                <ReactQuill theme="snow" modules={editorModules} formats={editorFormats}
                                            className="custom-ql-editor"/>
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={12}>
                        <Form.Item label="Mensaje 4" style={{ marginBottom: 0 }} />

                        <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator("subject4", {
                            })(
                                <Input
                                    prefix={
                                        <Icon type="message" style={{ color: "rgba(0,0,0,.25)" }} />
                                    }
                                    placeholder="Asunto"
                                />
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator("message4", {
                                initialValue: ''
                            })(
                                <ReactQuill modules={editorModules} formats={editorFormats}
                                            className="custom-ql-editor"/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={24}>
                        <Card style={{ marginBottom: "20px" }}>
                            <h4>Filtros</h4>
                            <Row gutter={16}>
                                <Col md={8}>
                                    <Form.Item label="Acción" style={{ marginBottom: 0 }} />
                                    <Form.Item>
                                        {getFieldDecorator("tld_filter_type", {
                                        })(
                                            <Select
                                                allowClear
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="Incluya o excluya tld"
                                            >
                                                {tLDFilterType.map(ft => (
                                                    <Select.Option key={ft.id} value={ft.id}>
                                                        {ft.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item label="Dominio de primer nivel(TLD)" style={{ marginBottom: 0 }} />
                                    <Form.Item>
                                        {getFieldDecorator("tld", {
                                        })(
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="Seleccione los dominios"
                                            >
                                                {topLevelDomains.map(tld => (
                                                    <Select.Option key={tld.name} value={tld.name}>
                                                        {tld.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item label="Dominio" style={{marginBottom: 0}}/>
                                    <Form.Item>
                                        {getFieldDecorator("op_domain", {})(
                                            <Input
                                                placeholder="Entre el dominio"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={8}>
                                    <Form.Item label="Acción" style={{marginBottom: 0}}/>
                                    <Form.Item>
                                        {getFieldDecorator("email_filter_type", {})(
                                            <Select
                                                allowClear
                                                showSearch
                                                style={{width: '100%'}}
                                                placeholder="Incluya o excluya email"
                                            >
                                                {tLDFilterType.map(ft => (
                                                    <Select.Option key={ft.id} value={ft.id}>
                                                        {ft.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item label="Dirección de Correo" style={{marginBottom: 0}}/>
                                    <Form.Item>
                                        {getFieldDecorator("contact_email", {
                                        })(
                                            <div>
                                                {emailsFilter.map((em, index) => {
                                                    const tagElem = (
                                                        <Tag key={em} closable
                                                             onClose={() => handleClose(em)}>
                                                            {em}
                                                        </Tag>
                                                    );
                                                    return (tagElem);
                                                })}
                                                {inputVisible && (
                                                    <Input
                                                        type="text"
                                                        size="small"
                                                        style={{width: 100}}
                                                        value={inputValue}
                                                        onChange={handleInputChange}
                                                        onBlur={handleInputConfirm}
                                                        onPressEnter={handleInputConfirm}
                                                    />
                                                )}
                                                {!inputVisible && (
                                                    <Tag onClick={showInput} style={{background: '#fff', borderStyle: 'dashed'}}>
                                                        <Icon type="plus"/>
                                                        Nueva dirección
                                                    </Tag>
                                                )}
                                            </div>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Button size="small" className="btn-info float-right" style={{ marginTop: "40px", padding:"0 35px"}}
                                            onClick={handleFilter} >
                                        Filtrar
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={24} style={{marginBottom: "20px"}}>
                        <Button style={{marginLeft:"25px"}} className="btn-info" icon="reload" size="small"
                                onClick={handleReloadData}>
                        </Button>
                        <Button style={{marginRight:"25px"}}className="float-right"  type="primary" icon="download" size="small" loading={downloading}
                                onClick={handleExport}>
                            Descargar
                        </Button>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={24}>
                        <Spin spinning={spinState}>
                            <Table columns={columns} className={"ant-table1-v1 table-striped-rows"}
                                   dataSource={contactTable.data}
                                   loading={contactTable.loading}
                            />
                        </Spin>
                    </Col>
                </Row>

                <Form.Item className="text-center" style={{ marginTop: 5 }}>
                    <Button className="btn-info" style={{ marginRight: 5 }}
                        icon="mail"
                        htmlType="submit"
                        size="large"
                        loading={submitting}
                    >
                        Enviar Correos Electrónicos
                    </Button>
                    <Link to="/campaigns/list">
                        <Button type="primary" size="large">
                            Cancelar
                        </Button>
                    </Link>
                </Form.Item>

                {getFieldDecorator("campaign", {
                    initialValue: props.id
                })(<input type="hidden" />)}

            </Form>
        </>
    );
};

export default withRouter(
    Form.create({ name: "campaign_emails_form" })(CampaignEmailForm)
);
