import Cliente from "../Cliente";

export const list = () => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}metrics/`);
};

export const get = (id) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}metrics/${id}`);
};

export const create = (values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}metrics/create`, {
            values
        });
};

export const edit = (id, values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .put(`${process.env.REACT_APP_API_URL}metrics/${id}`, {
            values
        });
};

export const remove = id => {
    return Cliente.setBearerToken()
        .getCliente()
        .delete(`${process.env.REACT_APP_API_URL}metrics/${id}`);
};