import React, { useEffect, useState } from "react";
import {Table, Divider, PageHeader, Popconfirm, Col, Button, Row, Input, Icon} from "antd";

import * as contactService from  "../../api/services/contact.service";

const Page = () => {
    const [contactTable, setContactTable] = useState({
        data: [],
        pagination: {},
        loading: false,
    });

    const [searchInput, setSearchInput] = useState(null);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        setSearchInput(node);
                    }}
                    placeholder={'Entre un valor'}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        }
    });

    const columns = [
        {
            title: "Web",
            dataIndex: "web",
            key: "web",
            ...getColumnSearchProps('web'),
        },
        {
            title: "Pais",
            dataIndex: "country",
            key: "country"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps('email'),
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Apellido",
            dataIndex: "lastname",
            key: "lastname"
        }
    ];

    useEffect(() => {
        getContacts();
    }, []);


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

   const handleReset = clearFilters => {
        clearFilters();
    };

    const handleContactTableChange = (pagination, filters, sorter) => {
        const pager = { ...contactTable.pagination };
        pager.current = pagination.current;
        setContactTable({
            pagination: pager,
        });

        getContacts({
            limit: pagination.pageSize,
            page: pagination.current,
            ...filters
        });
    };

    const getContacts = async (params = {}) => {
        try {
            setContactTable({
                loading: true
            });

            if(!params.limit){
                params.limit = 10;
            }
            if(!params.page){
                params.page = 1;
            }

            const result = await contactService.getConfirmedContacts(params);
            let items = [];
            result.data.items.map(item => {
                items.push({
                    key: item.id,
                    web: item.web,
                    country: (item.country) ? item.country.name : '-',
                    email: item.email,
                    name: (item.name) ? item.name : '-',
                    lastname: (item.lastname) ? item.lastname : '-',
                });
            });

            const pagination = { ...contactTable.pagination };
            pagination.total = result.data.items_count;
            setContactTable({
                loading: false,
                data: items,
                pagination,
            });
        } catch (e) {
        }
    };


    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Contactos"
                backIcon={null}
                subTitle="Listado de contactos confirmados"
            />
            <Row gutter={16}>
                <Col md={24}>
                    <Table columns={columns}
                           dataSource={contactTable.data}
                           pagination={contactTable.pagination}
                           loading={contactTable.loading}
                           onChange={handleContactTableChange}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Page;
