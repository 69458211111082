import Cliente from "../Cliente";

export const list = () => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}outreacher-email/`);
};

export const show = id => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}outreacher-email/${id}`);
};

export const destroy = id => {
  return Cliente.setBearerToken()
    .getCliente()
    .delete(`${process.env.REACT_APP_API_URL}outreacher-email/${id}`);
};
