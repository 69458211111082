import React, { useEffect, useState } from "react";

import {
    Table,
    Tabs
} from "antd";

const LinkList = props => {
    const { TabPane } = Tabs;

    const [matchedTable, setMatchedTable] = useState({
        data: [],
        loading: true,
    });
    const [unmatchedTable, setUnmatchedTable] = useState({
        data: [],
        loading: true,
    });

    useEffect(() => {
        (() => {
            try {
               loadData(props.data);
            } catch (err) {}
        })();
    }, [props]);

    const columns = [
        {
            title: "Domain Rating",
            dataIndex: "dr",
            key: "dr",
        },
        {
            title: "Referring Domain",
            dataIndex: "referring_domain",
            key: "referring_domain"
        },
        {
            title: "First See",
            dataIndex: "first_see",
            key: "first_see"
        },
        {
            title: "Language",
            dataIndex: "language",
            key: "language"
        },
        {
            title: "Traffic",
            dataIndex: "traffic",
            key: "traffic"
        }
    ];

    const loadData = (data) => {
        let matchedItems = [];
        let unmatchedItems = [];

        data.matched.map((it, i) => {
            matchedItems.push({
                key: i + 1,
                dr: it.domain_rating,
                referring_domain: it.referring_domain,
                first_see: it.first_seen,
                language: it.language,
                traffic: it.traffic
            });
        });

        data.unmatched.map((it, i) => {
            unmatchedItems.push({
                key: i + 1,
                dr: it.domain_rating,
                referring_domain: it.referring_domain,
                first_see: it.first_seen,
                language: it.language,
                traffic: it.traffic
            });
        });

        setMatchedTable({
            loading: false,
            data: matchedItems
        });
        setUnmatchedTable({
            loading: false,
            data: unmatchedItems
        });
    };

    return (
        <>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Matched" key="1">
                    <Table columns={columns}
                           dataSource={matchedTable.data}
                           loading={matchedTable.loading}/>
                </TabPane>
                <TabPane tab="Unmachet" key="2">
                    <Table columns={columns}
                           dataSource={unmatchedTable.data}
                           loading={unmatchedTable.loading}
                           />
                </TabPane>
            </Tabs>
        </>
    );
};

export default LinkList;
