import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
    Table,
    Tabs,
    Divider,
    PageHeader,
    Popconfirm,
    Col,
    Button,
    Row,
    Input,
    Icon,
    Spin,
    Drawer
} from "antd";
import FileForm from "../summary/files-form";
import LinkList from "../summary/links";
import SearchForm from "../summary/search-form";
import XLSX from "xlsx";
import moment from "moment";

const Page = () => {
    const { TabPane } = Tabs;

    const [spinState, setSpinState] = useState(false);
    const [showBacklinks, setShowBacklinks] = useState(false);
    const [showSummaryTable, setShowSummaryTable] = useState(false);

    const [backlinkSummaryTable, setBacklinkSummaryTable] = useState({
        data: [],
        loading: false,
    });

    const [baseBacklinks, setBaseBacklinks] = useState({
        names: [],
        backlinks:[]
    });

    const [backlinks, setBacklinks] = useState([]);

    const [drawerState, setDrawerState] = useState({ visible: false });

    const columns = [
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Dofollow domains",
            dataIndex: "dofollow",
            key: "dofollow"
        },
        {
            title: "Matched domains",
            dataIndex: "matched",
            key: "matched"
        },
        {
            title: "Unmatched domains",
            dataIndex: "unmatched",
            key: "unmatched"
        }
    ];

    useEffect(() => {
    }, []);

    useEffect(() => {
    }, [backlinkSummaryTable, baseBacklinks, backlinks]);


    const showBacklinkSummary = async (names, data) => {
        try {
            setBacklinkSummaryTable({
                loading: true
            });

            let items = [];
            let summary = [];
            let totalMatched = 0;
            let totalUnmatched = 0;

            data.map((it, i) => {
                if (i > 0) {
                    const matched = it.filter(cl => data[0].some(cm => cl.referring_domain === cm.referring_domain));
                    const unmatched = it.filter(cl => !data[0].some(cm => cl.referring_domain === cm.referring_domain));
                    summary.push({
                        matched: matched,
                        unmatched: unmatched
                    });
                    totalMatched = totalMatched + matched.length;
                    totalUnmatched = totalUnmatched + unmatched.length;
                }
            });

            items.push({
                    key: names[0],
                    name: names[0],
                    dofollow: data[0].length,
                    matched: totalMatched,
                    unmatched: totalUnmatched,
                }
            );
            summary.map((it, i) => {
                items.push({
                    key: names[i + 1],
                    name: names[i + 1],
                    dofollow: data[i + 1].length,
                    matched: it.matched.length,
                    unmatched: it.unmatched.length,
                });
            });

            setBacklinkSummaryTable({
                loading: false,
                data: items
            });
            setBacklinks(summary);
            setBaseBacklinks({
                    names: names,
                    backlinks: summary
                }
            );
            setShowSummaryTable(true);
            setShowBacklinks(true);
        } catch (e) {
            console.log(e);
        }
    };

    const readData = (file) => {
        const reader = new FileReader();
        return new Promise(resolve => {
            reader.onload = function (e) {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, {type: 'array', cellDates:true});
                const wsName = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsName];
                const list = XLSX.utils.sheet_to_json(ws, {header: 1});
                let items = [];
                list.map((it, i) => {
                    if (i > 0) {
                        let obj = {
                            'domain_rating': it[2],
                            'referring_domain': (it[5].length) ? it[5].replace(/^https?\:\/\//i, "").split("/")[0] : "-",
                            'referring_page_url': it[5],
                            'first_seen': (it[15]) ?
                                (moment(it[15], "DD/MM/YYYY HHh:mm:ss").format("YYYY-MM-DD")) : "",
                            'language': (it[18]) ? it[18] : "",
                            'traffic': it[19]
                        };
                        items.push(obj);
                    }
                })
                const result = {
                    name: wsName,
                    backlinks: items
                };
                resolve(result);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const processLinks = async (file, files) => {
        setSpinState(true);
        let names = [];
        let result = [];
        let data = await readData(file);
        if (data) {
            names.push(data.name);
            result.push(data.backlinks);
        };

        await Promise.all(
            files.map(async it => {
                data = await readData(it);
                if(data) {
                    names.push(data.name);
                    result.push(data.backlinks);
                }
            })
        );

        setShowBacklinks(false);
        setShowSummaryTable(false);
        setSpinState(false);

        showBacklinkSummary(names, result);
    }

    const handleFilter = (filter) => {

        setDrawerState({
            visible: false,
        });
        setSpinState(true);

        let data  = [...baseBacklinks.backlinks];
        let result = [];
        let filtered = false;

        if ((typeof filter.filter_tld_type !== 'undefined') && (typeof filter.filter_tld !== 'undefined')
            && filter.filter_tld.length > 0) {
            data.map(it => {
                let matched = [];
                let unmatched = [];

                if (filter.filter_tld_type === "include") {
                    matched = it.matched.filter(cl => {
                        const domainParts = cl.referring_domain.split(".");
                        return filter.filter_tld.includes(`.${domainParts[domainParts.length - 1]}`);
                    });

                    unmatched = it.unmatched.filter(cl => {
                        const domainParts = cl.referring_domain.split(".");
                        return filter.filter_tld.includes(`.${domainParts[domainParts.length - 1]}`)
                    });


                } else {
                    matched = it.matched.filter(cl => {
                        const domainParts = cl.referring_domain.split(".");
                        return !filter.filter_tld.includes(`.${domainParts
                            [domainParts.length - 1]}`)
                    });

                    unmatched = it.unmatched.filter(cl => {
                        const domainParts = cl.referring_domain.split(".");
                       return !filter.filter_tld.includes(`.${domainParts
                            [domainParts.length - 1]}`)
                    });
                }

                result.push({
                    matched: matched,
                    unmatched: unmatched
                });
            });

            filtered = true;
        }

        if ((typeof filter.filter_date_type !== 'undefined') && (typeof filter.filter_tld !== 'filter_date')) {

            if (result.length > 0) {
                data = [...result];
                result = [];
            }

            data.map(it => {
                let matched = [];
                let unmatched = [];

                if (filter.filter_date_type === "mayor") {
                    matched = it.matched.filter(cl =>  moment(cl.first_seen, "YYYY-MM-DD").isSameOrAfter(filter.filter_date));
                    unmatched = it.unmatched.filter(cl =>  moment(cl.first_seen, "YYYY-MM-DD").isSameOrAfter(filter.filter_date));
                } else {
                    matched = it.matched.filter(cl =>  moment(cl.first_seen, "YYYY-MM-DD").isSameOrBefore(filter.filter_date));
                    unmatched = it.unmatched.filter(cl =>  moment(cl.first_seen, "YYYY-MM-DD").isSameOrBefore(filter.filter_date));
                }

                result.push({
                    matched: matched,
                    unmatched: unmatched
                });

            });

            filtered = true;
        }

        if (filtered === true) {
            setBacklinks(result);
        } else {
            setBacklinks(data);
        }
        setSpinState(false);
    };

    const showDrawer = () => {
        setDrawerState({
            visible: true,
        });
    };

    const onCloseDrawer = () => {
        setDrawerState({
            visible: false,
        });
    };

    const handleExport = () => {
        const wb = XLSX.utils.book_new();
        baseBacklinks.names.map((name, i) => {
            if(i === 0) {
                let data = [["Nombre", "Dofollow domains", "Matched domains","Unmatched domains"]];
                backlinkSummaryTable.data.map( summary => {
                    let item = [summary.name, summary.dofollow, summary.matched, summary.unmatched];
                    data.push(item);
                })
                const ws = XLSX.utils.aoa_to_sheet(data);
                XLSX.utils.book_append_sheet(wb, ws, "Resumen");
            } else {
                let data = [];
                data.push(["Matched", "", "", "", ""]);
                data.push(["Domain Rating", "Referring Domain", "First See","Language", "Traffic"]);
                backlinks[i-1].matched.map(element => {
                    data.push([element.domain_rating, element.referring_domain, element.first_seen,
                        element.language, element.traffic]);
                });
                data.push(["", "", "", "", ""]);
                data.push(["Unmatched", "", "", "", ""]);
                backlinks[i-1].unmatched.map(element => {
                    data.push([element.domain_rating, element.referring_domain, element.first_seen,
                        element.language, element.traffic]);
                });
                const ws = XLSX.utils.aoa_to_sheet(data);
                XLSX.utils.book_append_sheet(wb, ws, name);
            }
        });

        XLSX.writeFile(wb, "resumen.xlsx")
    };

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Backlink Spy"
                backIcon={null}
            />
            <Spin spinning={spinState}>
                <Row gutter={16}>
                    <Col md={24}>
                        <FileForm onProcessLinks={processLinks}/>
                    </Col>
                </Row>

                {showSummaryTable === true &&
                <Row gutter={16}>
                    <Col md={24} style={{marginTop: 20}}>
                        <Table columns={columns}
                               dataSource={backlinkSummaryTable.data}
                               loading={backlinkSummaryTable.loading}
                               pagination={false}/>
                    </Col>
                </Row>
                }
               {showBacklinks === true &&
               <Row gutter={16}>
                <Col md={24} style={{marginTop: 20, marginBottom: 20}}>
                    <Button type="primary" icon="search" size="small" style={{marginRight: 5}}  onClick={showDrawer}>
                        Filtrar
                    </Button>

                    <Button type="primary" icon="download" size="small" loading={false} onClick={handleExport}>
                        Exportar
                    </Button>
                    <Drawer
                        title="Filtrar por"
                        width={'40%'}
                        onClose={onCloseDrawer}
                        visible={drawerState.visible}
                        bodyStyle={{ paddingBottom: 40 }}
                    >
                        <SearchForm onFilter={handleFilter} onCancel={onCloseDrawer}/>
                    </Drawer>
                </Col>

                <Col md={24}>
                    <Tabs defaultActiveKey="1" type="card">
                        {
                            backlinks.map((bl, i) =>
                            <TabPane tab={baseBacklinks.names[i + 1]} key={i + 1}><LinkList data={bl}/> </TabPane>)
                        }
                    </Tabs>,
                </Col>
                </Row>
                }
            </Spin>

        </>
    );
};

export default Page;
