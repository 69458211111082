import React, { useEffect, useState } from "react";
import {
    Form,
    Icon,
    Input,
    Button,
    Row,
    Col,
    message,
    Select,
    Spin
} from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import * as templateService from  "../../api/services/template.service";
import * as mailingService from  "../../api/services/mailing.service";

const ReplyEmailForm = props => {
    const { getFieldDecorator } = props.form;
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [spinState, setSpinState] = useState(false);

    const editorModules = {
        toolbar: [
            [{ 'size': ['small', 'normal', 'large'] }],
            [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
        ],
    };

    const editorFormats = [
        'size',
        'header',
        'font',
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent',
        'link'
    ];

    useEffect(() => {
        (async () => {
            try {
                setFormData();
                getTemplates();
            } catch (err) {}
        })();
    }, []);

    const setFormData = () => {
        props.form.setFields({
            to: {
                value: props.data.from_email
            }
        });
    };

    const getTemplates = async () => {
        const templatesResponse = await templateService.list();
        let list = [];
        templatesResponse.data.map(item => {
            list.push({
                name: item.Name
            });
        });

        setTemplates(list);
        setLoading(false);
    }

    const validateMessage = () => {
        if(!props.form.getFieldValue('message').replace(/<[^>]*>/g, "").trim()) {
            props.form.setFields({
                message: {
                    value: ""
                },
            })
        }
    };

    const handleSubmit = e => {
        e.preventDefault();

        validateMessage();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                setSpinState(true);
                try {
                    const sent = await mailingService.replyEmail(props.data.id, values);
                    setSpinState(false);

                    if(sent && sent.data.success == true) {
                        message.success("El email fue configurado y se enviará en unos segundos");
                        if (props.onCancel) {
                            props.onCancel();
                        }
                    } else {
                        message.error("Ha ocurrido un error enviando el email");
                    }
                } catch (e) {
                    setSpinState(false);
                    message.error("Ha ocurrido un error enviando el email");
                }
            } else {
                message.warning("Por favor, verifique que todos los campos estén correctos");
            }
        });
    };

    const handleCancel = e => {
        if(props.onCancel){
            props.onCancel();
        }
    }

    return (
        <>
            <Spin spinning={spinState}>
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row gutter={16}>
                        <Col md={8}>
                            <Form.Item label="Para" style={{marginBottom: 0}}/>
                            <Form.Item>
                                {getFieldDecorator("to", {
                                })(
                                    <Input disabled={true}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item required label="Plantilla" style={{marginBottom: 0}}/>
                            <Form.Item>
                                {getFieldDecorator("template", {
                                    rules: [
                                        {required: true, message: "El campo Plantilla es requerido"}
                                    ]
                                })(
                                    <Select
                                        loading={loading}
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Seleccione una plantilla"
                                    >
                                        {templates.map(template => (
                                            <Select.Option key={template.name} value={template.name}>
                                                {template.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item required label="Asunto" style={{ marginBottom: 0 }} />
                            <Form.Item>
                                {getFieldDecorator("subject", {
                                    rules: [
                                        { required: true, message: "El campo Asunto es requerido" }
                                    ]
                                })(
                                    <Input
                                        prefix={
                                            <Icon type="message" style={{ color: "rgba(0,0,0,.25)" }} />
                                        }
                                        placeholder="Asunto"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col md={24}>
                            <Form.Item required label="Mensaje" style={{marginBottom: 0}}/>
                            <Form.Item>
                                {getFieldDecorator("message", {
                                    rules: [
                                        {required: true, message: "El campo Mensaje es requerido"}
                                    ],
                                    initialValue: ''
                                })(
                                    <ReactQuill theme="snow" modules={editorModules} formats={editorFormats}
                                                className="custom-ql-editor"/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className="text-center">
                        <Button style={{marginRight: 5}}
                            type="primary"
                            size="large"
                            onClick={handleCancel}
                        >
                            Cancelar

                        </Button>

                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                        >
                            Enviar
                        </Button>
                    </Form.Item>

                    {getFieldDecorator("opportunity", {
                        initialValue: props.data.opportunity.id
                    })(<input type="hidden" />)}

                    {getFieldDecorator("name", {
                        initialValue: props.data.from_name
                    })(<input type="hidden" />)}
                </Form>
            </Spin>
        </>
    );
};

export default (
    Form.create({ name: "reply_email_form" })(ReplyEmailForm)
);
