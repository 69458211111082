import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    Form,
    Icon,
    Input,
    Button,
    Select,
    Row,
    Col,
    PageHeader,
    Switch,
    Upload,
    message,
    Table,
    Divider,
    AutoComplete,
    Tag,
    message as Message
} from "antd";

import * as service from "../../api/services/country.service";
import * as projectservice from  "../../api/services/project.service";
import * as campaignService from  "../../api/services/campaign.service";
import * as metricService from  "../../api/services/metric.service";

const CampaignCreateForm = props => {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmit] = useState(false);
    const [countries, setCountries] = useState([]);
    const [projects, setProjects] = useState([]);
    const [campaignsType, setCampaignsType] = useState([]);
    const [discoveryType, setDiscoveryType] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [selectedCampaignsType, setSelectedCampaignsType] = useState("");
    const [inputVisible, setInputVisible]= useState(false);
    let [keywords, setKeywords] = useState([]);
    const [inputValue, setInputValue]= useState("");

    useEffect(() => {
        (async () => {
            try {
                const countryList = await getCountries();
                const projects = await getProjects();
                const campaignsType = getCampaignsType();
                const discoveryType = await getDiscoveryType();
                const metrics = await getMetricsFilter();
                setLoading(false);
                setCountries(countryList);
                setProjects(projects.data.items);
                setCampaignsType(campaignsType);
                setDiscoveryType(discoveryType);
                setMetrics(metrics.data.items);
            } catch (err) {}
        })();
    }, []);

    const getCountries = async () =>{
        // const result =  await service.getCountries();
        // return result.data;

        const defaultCountries = [
            { iso2: "AR", name: "Argentina"}, { iso2: "AU", name: "Australia"}, { iso2: "AT", name: "Austria"},
            { iso2: "BE", name: "Belgium"}, { iso2: "BR", name: "Brazil"}, { iso2: "BN", name: "Brunei"},
            { iso2: "CA", name: "Canada"}, { iso2: "CL", name: "Chile"}, { iso2: "CO", name: "Colombia"},
            { iso2: "DK", name: "Denmark"}, { iso2: "FI", name: "Finland"}, { iso2: "FR", name: "France"},
            { iso2: "DE", name: "Germany"}, { iso2: "HK", name: "Hong Kong"}, { iso2: "IN", name: "India"},
            { iso2: "ID", name: "Indonesia"}, { iso2: "IE", name: "Ireland"}, { iso2: "IL", name: "Israel"},
            { iso2: "IT", name: "Italy"}, { iso2: "MY", name: "Malaysia"}, { iso2: "MX", name: "Mexico"},
            { iso2: "NL", name: "Netherlands"}, { iso2: "NZ", name: "New Zealand"}, { iso2: "NO", name: "Norway"},
            { iso2: "PE", name: "Peru"}, { iso2: "PH", name: "Philippines"}, { iso2: "PL", name: "Poland"},
            { iso2: "RU", name: "Russia"}, { iso2: "SG", name: "Singapore"}, { iso2: "ZA", name: "South Africa"},
            { iso2: "ES", name: "Spain"}, { iso2: "SE", name: "Sweden"}, { iso2: "CH", name: "Switzerland"},
            { iso2: "TH", name: "Thailand"}, { iso2: "TR", name: "Turkey"}, { iso2: "UA", name: "Ukraine"},
            { iso2: "AE", name: "United Arab Emirates"}, { iso2: "GB", name: "United Kingdom"},
            { iso2: "US", name: "United States"}, { iso2: "VN", name: "Vietnam"},
         ];

        return defaultCountries;
    }

    const getProjects = async () => {
        const projects = await projectservice.getProjects();

        return projects;
    }

    const getMetricsFilter = async () => {
        const metrics = await metricService.list();

        return metrics;
    }

    const getCampaignsType = () => {
        const campaignsType = [
            {
                id: 'import-campaign',
                name: 'Import Campaign'
            }, {
                id: 'discovery-campaign',
                name: 'Discovery Campaign'
            }
        ]
        return campaignsType;
    }

    const getDiscoveryType = async () => {
        const discoveryType = [
            {
                id: 'advanced_operator_search',
                name: 'Advanced Operator Search'
            }, {
                id: 'blogger_outreach',
                name: 'Blogger Outreach'
            }
        ];

        return discoveryType;
    }

    const beforeUpload = (file) => {
        const arr = file.name.split(".");
        const ext = arr[arr.length - 1].toLowerCase();
        props.form.setFieldsValue({
            file: null
        });

        if (ext !== "csv") {
            message.error("Archivo no disponible. Solo con extensión .csv");
            return;
        }

        setFileList([file]);

        return false;
    };

    const fillData = (file) => {
        const reader = new FileReader();
        return new Promise(resolve => {
            reader.onload = (event) => {
                const result = [];
                let data = reader.result;
                const rows = data.split(/\r\n|\r|\n/);
                let i = 0;
                rows.forEach(row => {
                    if (row) {
                        if (i > 0) {
                            let item = {
                                website: "",
                            };
                            const fields = row.split(/,|;/);

                            fields.forEach((field, idx) => {
                                if (idx == 0) {
                                    item.website = field.trim();
                                }
                            });
                            result.push(item);
                        }
                        i++;
                    }
                });
                resolve(result);
            }
            reader.readAsText(file);
        });
    };

    const onRemove = () => {
        setFileList([]);
        return {
            fileList: []
        };
    };

    const handleCampaignTypeChange = e => {
       setSelectedCampaignsType(e);
       setKeywords([]);
    };

    const handleClose = removedTag => {
        const tags = keywords.filter(tag => tag !== removedTag);
        setKeywords(tags);
        props.form.setFieldsValue({
            keywords: tags.join(','),
            tags: tags
        });
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = e => {
        setInputValue((e.target.value));
    };

    const handleInputConfirm = () => {
        if (inputValue && keywords.indexOf(inputValue) === -1) {
            keywords = [...keywords, inputValue];
        }
        setKeywords(keywords);
        setInputVisible(false);
        setInputValue('');
        props.form.setFieldsValue({
            keywords: keywords.join(','),
            tags: keywords
        });
    };

    const handleSubmit = e => {
        e.preventDefault();

        if(fileList.length <= 0){
            props.form.setFieldsValue({
                file: null
            });
        }
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setSubmit(true);
                    if (selectedCampaignsType === 'import-campaign') {
                        const formData = new FormData();
                        Object.keys(values).map(v => {
                            if(v !=="file") {
                                formData.append(v, values[v]);
                            }
                        });
                        formData.append("file", fileList[0]);

                        await campaignService.createImportCampaign(formData);
                    } else {
                        await campaignService.createDiscoveryCampaign(values);
                    }
                    setSubmit(false);
                    message.success("La campaña se ha creado satisfactoriamente");
                    props.history.push("/campaigns/list");
                } catch (e) {
                    setSubmit(false);
                    message.error("Ha ocurrido un error creando la campaña");
                }
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Agregar Campaña"
                backIcon={null}
            />

            <Form onSubmit={handleSubmit} className="login-form" autoComplete="off">
                <Row gutter={16}>
                    <Col md={6}>
                        <Form.Item required label="Nombre" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("name", {
                                rules: [
                                    { required: true, message: "El campo Nombre es requerido" }
                                ]
                            })(
                                <Input
                                    placeholder="Nombre"
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={6}>
                        <Form.Item required label="Proyecto" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("project", {
                                rules: [
                                    { required: true, message: "El campo Proyecto es requerido" }
                                ]
                            })(
                                <Select
                                    loading={loading}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione un proyecto"
                                >
                                    {projects.map(project => (
                                        <Select.Option key={project.id} value={project.id}>
                                            {project.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={6}>
                        <Form.Item required label="Pais" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("country", {
                                rules: [
                                    { required: true, message: "El campo Pais es requerido" }
                                ]
                            })(
                                <Select
                                    loading={loading}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione un pais"
                                >
                                    {countries.map(country => (
                                        <Select.Option key={country.iso2} value={country.iso2}>
                                            {country.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={6}>
                        <Form.Item required label="Campaña" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("campaignsType", {
                                // initialValue: selectedCampaignsType,
                                rules: [
                                    { required: true, message: "El Tipo de Campaña es requerido" }
                                ]
                            })(
                                <Select
                                    loading={loading}
                                    optionFilterProp="children"
                                    placeholder="Seleccione un tipo"
                                    onChange={handleCampaignTypeChange}
                                >
                                    {campaignsType.map(type => (
                                        <Select.Option key={type.id} value={type.id}>
                                            {type.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={6}>
                        <Form.Item label="Filtro de Métrica" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("metric", {
                            })(
                                <Select
                                    loading={loading}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione un valor"
                                >
                                    {metrics.map(metric => (
                                        <Select.Option key={metric.id} value={metric.id}>
                                            {metric.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    { selectedCampaignsType === 'import-campaign' &&
                    <Col md={6}>
                        <Form.Item label="Archivo CSV" extra="" style={{marginBottom: 4}}>
                            {getFieldDecorator("file", {
                                rules: [
                                    { required: true, message: "Seleccione un archivo" }
                                ]
                            })(<Upload
                                beforeUpload={beforeUpload}
                                onRemove={onRemove}
                                fileList={fileList}
                                accept={"text/*"}
                            >
                                <Button>
                                    <Icon type="upload"/>
                                    Clic para subir csv
                                </Button>
                            </Upload>
                            )}
                        </Form.Item>
                    </Col>
                   }

                    {selectedCampaignsType === 'discovery-campaign' &&
                    <Col md={6}>
                        <Form.Item required label="Palabras Claves" style={{marginBottom: 0}}/>
                        <Form.Item>
                            {getFieldDecorator("keywords", {
                                rules: [
                                    {required: true, message: "El campo Palabras claves es requerido"}
                                ]
                            })(
                                <div>
                                    {keywords.map((keyword, index) => {
                                        const tagElem = (
                                            <Tag key={keyword} closable
                                                 onClose={() => handleClose(keyword)}>
                                                {keyword}
                                            </Tag>
                                        );
                                        return (tagElem);
                                    })}
                                    {inputVisible && (
                                        <Input
                                            type="text"
                                            size="small"
                                            style={{width: 100}}
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            onBlur={handleInputConfirm}
                                            onPressEnter={handleInputConfirm}
                                        />
                                    )}
                                    {!inputVisible && (
                                        <Tag onClick={showInput} style={{background: '#fff', borderStyle: 'dashed'}}>
                                            <Icon type="plus"/>
                                            New Tag
                                        </Tag>
                                    )}
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                    }

                    {selectedCampaignsType === 'discovery-campaign' &&
                    <Col md={6}>
                        <Form.Item required label="Tipo de Descubrimiento" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("discoveryType", {
                                rules: [
                                    { required: true, message: "El campo Tipo de Descubrimiento" }
                                ]
                            })(
                                <Select
                                    loading={loading}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione el tipo"
                                >
                                    {discoveryType.map(type => (
                                        <Select.Option key={type.id} value={type.id}>
                                            {type.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                   }

                </Row>

                <Form.Item className="text-center">
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={submitting}
                    >
                        Crear
                    </Button>
                </Form.Item>

                {selectedCampaignsType === 'discovery-campaign'}
                {getFieldDecorator("tags", {
                    initialValue: []
                })(<input type="hidden" />)}

            </Form>
        </>
    );
};

export default withRouter(
    Form.create({ name: "campaign_create_form" })(CampaignCreateForm)
);
