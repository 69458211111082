import React, { useEffect, useState } from "react";
import {Switch, PageHeader, Row, Col, Card} from "antd";
import { useThemeSwitcher } from "react-css-theme-switcher";

const UserPreference = () => {

  const { switcher, currentTheme, status, themes } = useThemeSwitcher();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setCurrentTheme();
  }, []);

  const setCurrentTheme = () => {
    if (localStorage.getItem("theme") === "dark") {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  }

  const toggleTheme = (isChecked) => {
    if (isChecked) {
      localStorage.setItem("theme", "dark");
    } else {
      localStorage.setItem("theme", "light");
    }

    setIsDarkMode(isChecked);
    switcher({theme: isChecked ? themes.dark : themes.light});
  };

  return (
    <>
      <PageHeader
          onBack={() => null}
          title="Preferencias de Usuario"
          backIcon={null}
      />
      <Card>
        <Row gutter={16}>
          <Col md={24}>
            <span>Modo oscuro: </span>
            <Switch checked={isDarkMode} onChange={toggleTheme} key="themeSwitch"/>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default UserPreference;
