import React, {useEffect, useState} from "react";
import { Layout, Menu, Icon } from "antd";
import { NavLink, Link } from "react-router-dom";
import token from "../api/token";
import * as SVGIcon from "../components/SVGIcon";
const { Sider } = Layout;
const { SubMenu } = Menu;

const keys = [
  "/",
  "/interconecting/listar",
  "/usuarios/listar",
  "/usuarios/agregar",
  "/emails/listar",
  "/emails/agregar",
  "/campaigns/list",
  "/metrics/list",
  "/templates/list",
  "/pitchbox/send-email",
  "/pitchbox/mailing",
  "/pitchbox/emails/inbox",
  "/twitter/follows",
  "/twitter/send-tweet",
  "/backlink-spy/summary",
  "/facebook",
  "/linkedin",
  "/user/preferences",
];

const SideBar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [keySelected, setKeySelected] = useState("");

  const DashboardIcon = props => <Icon component={SVGIcon.DashboardIcon()} {...props} />
  const BacklinkIcon = props => <Icon component={SVGIcon.BacklinkIcon()} {...props} />;
  const CampaignIcon = props => <Icon component={SVGIcon.CampaignIcon()} {...props} />;
  const ContactsIcon = props => <Icon component={SVGIcon.ContactsIcon()} {...props} />;
  const FacebookIcon = props => <Icon component={SVGIcon.FacebookIcon()} {...props} />;
  const InboxIcon = props => <Icon component={SVGIcon.InboxIcon()} {...props} />;
  const LinkedinIcon = props => <Icon component={SVGIcon.LinkedinIcon()} {...props} />;
  const SettingIcon = props => <Icon component={SVGIcon.SettingIcon()} {...props} />;
  const SkypeIcon = props => <Icon component={SVGIcon.SkypeIcon()} {...props} />;
  const TwitterIcon = props => <Icon component={SVGIcon.TwitterIcon()} {...props} />;

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const isActive = (match, location) => {
    if (!match) {
      return false;
    }

    setKeySelected(keys.find(item => item === match.url));

    return match;
  };

  const isGestor = () => {
    return token().rol === "gestor";
  };

  const isOutreachManager = () => {
    return token().rol === "outreach_manager";
  }

  if (!token()) return null;

  return (
    <>
      <Sider collapsed={props.collapsed} onCollapse={onCollapse}>
        {props.collapsed === true &&
        <div className="logo">I</div>
        }
        {props.collapsed === false &&
        <div className="logo">
          <span style={{display: "block", lineHeight: 1.2, padding: 10, letterSpacing: 2}}>INTELLIGENT
            <span style={{display: "block", letterSpacing: 8, fontSize: 14, fontWeight: "normal"}}>OUTREACH</span>
          </span>
        </div>
        }
        <Menu mode="inline" selectedKeys={[keySelected]}>
          <Menu.Item key="/">
            <NavLink to="/" isActive={isActive}>
              <DashboardIcon/>
              <span>Panel de Control</span>
            </NavLink>
          </Menu.Item>

          {!isGestor() && (
              <SubMenu
                  key="pitchbox"
                  title={
                    <span>
                      <InboxIcon/>
                      <span>Mail</span>
                    </span>
                  }
              >
                <Menu.Item key="/pitchbox/emails/inbox">
                  <NavLink
                      to="/pitchbox/emails/inbox"
                      isActive={isActive}
                  >
                    <span>Recibidos</span>
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="/pitchbox/send-email">
                  <NavLink
                      to="/pitchbox/send-email"
                      isActive={isActive}
                  >
                    <span>Enviar Emails</span>
                  </NavLink>
                </Menu.Item>
              </SubMenu>
          )}

          {!isGestor() && (
              <SubMenu
                  key="campaign"
                  title={
                    <span>
                      <CampaignIcon/>
                      <span>Campañas</span>
                    </span>
                  }
              >
                <Menu.Item key="/campaigns/list">
                  <NavLink
                      to="/campaigns/list"
                      isActive={isActive}
                  >
                    <span>Listado</span>
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="/pitchbox/mailing">
                  <NavLink
                      to="/pitchbox/mailing"
                      activeClassName="ant-menu-item-selected"
                      isActive={isActive}
                  >
                    <span>Estado de envios</span>
                  </NavLink>
                </Menu.Item>
              </SubMenu>
          )}

          {!isGestor() && (
              <Menu.Item key="/backlink-spy/summary">
                <NavLink
                    to="/backlink-spy/summary"
                    isActive={isActive}
                >
                  <BacklinkIcon style={{ color: 'inherit' }} />
                  <span>Backlink Spy</span>
                </NavLink>
              </Menu.Item>
          )}
          {!isGestor() && /*isOutreachManager() &&*/ (
              <SubMenu
                  key="twitter"
                  title={
                    <span>
                      <TwitterIcon/>
                      <span>Twitter</span>
                    </span>
                  }
              >

                <Menu.Item key="/twitter/follows">
                  <NavLink
                      to="/twitter/follows"
                      isActive={isActive}
                  >
                    <Icon type="pie-chart" />
                    <span>Seguidores</span>
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="/twitter/send-tweet">
                  <NavLink
                      to="/twitter/send-tweet"
                      isActive={isActive}
                  >
                    <Icon type="pie-chart" />
                    <span>Enviar Mensaje</span>
                  </NavLink>
                </Menu.Item>

              </SubMenu>
          )}

          {!isGestor() && (
              <Menu.Item key="/facebook">
                <NavLink
                    to="/facebook"
                    isActive={isActive}
                >
                  <FacebookIcon/>
                  <span>Facebook</span>
                </NavLink>
              </Menu.Item>
          )}

          {!isGestor() && (
              <Menu.Item key="/linkedin">
                <NavLink
                    to="/linkedin"
                    isActive={isActive}
                >
                  <LinkedinIcon/>
                  <span>LinkedIn</span>
                </NavLink>
              </Menu.Item>
          )}

          {!isGestor() && (
              <Menu.Item key="/skype">
                <NavLink
                    to="/skype"
                    isActive={isActive}
                >
                  <SkypeIcon/>
                  <span>Skype</span>
                </NavLink>
              </Menu.Item>
          )}

          {!isGestor() && (
              <Menu.Item key="/interconecting/listar">
                <NavLink to="/interconecting/listar" activeClassName="ant-menu-item-selected"
                         isActive={isActive}>
                  <ContactsIcon />
                  <span>Contactos</span>
                </NavLink>
              </Menu.Item>
          )}

          {!isGestor() && (
              <SubMenu
                  key="settings"
                  title={
                    <span>
                      <SettingIcon/>
                      <span>Ajustes</span>
                    </span>
                  }
              >
                <Menu.Item key="/user/preferences">
                  <NavLink
                      to="/user/preferences"
                      isActive={isActive}
                  >
                    <span>Preferencias</span>
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="/metrics/list">
                  <NavLink
                      to="/metrics/list"
                      isActive={isActive}
                  >
                    <span>Métricas</span>
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="/templates/list">
                  <NavLink to="/templates/list" activeClassName="ant-menu-item-selected"
                           isActive={isActive}>
                    <span>Plantillas</span>
                  </NavLink>
                </Menu.Item>
              </SubMenu>
          )}

          {isGestor() && (
              <SubMenu
                  key="users"
                  title={
                    <span>
                      <Icon type="user" />
                      <span>Usuarios</span>
                    </span>
                  }
              >
                <Menu.Item key="3" key="/usuarios/agregar">
                  <NavLink
                      to="/usuarios/agregar"
                      isActive={isActive}
                  >
                    Agregar
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="2" key="/usuarios/listar">
                  <NavLink
                      to="/usuarios/listar"
                      isActive={isActive}
                  >
                    Listar
                  </NavLink>
                </Menu.Item>
              </SubMenu>
          )}

          {isGestor() && (
              <SubMenu
                  key="emails"
                  title={
                    <span>
                      <Icon type="mail" />
                      <span>Emails</span>
                    </span>
                  }
              >
                <Menu.Item key="3" key="/emails/agregar">
                  <NavLink
                      to="/emails/agregar"
                      isActive={isActive}
                  >
                    Agregar
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="2" key="/emails/listar">
                  <NavLink
                      to="/emails/listar"
                      isActive={isActive}
                  >
                    Listar
                  </NavLink>
                </Menu.Item>
              </SubMenu>
          )}

        </Menu>
      </Sider>
    </>
  );
};

export default SideBar;
