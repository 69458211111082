import React, {useEffect, useState} from "react";
import {Layout, Menu, Row, Col, Icon} from "antd";
import { Link } from "react-router-dom";
import token from "../api/token";

const { Header } = Layout;

const AppHeader = (props) => {
  const [collapsed, setCollapsed] = useState(false);

  const handlerClickLogout = () => {
    localStorage.removeItem("session");
    window.location.href = "/login";
  };

  const toggle = () => {
    if (props.toggleSideBar) {
      props.toggleSideBar(!collapsed);
      setCollapsed(!collapsed);
    }
  }

  return (
    <>
      <Header>
        <Row type="flex" justify="space-between">
          <Col>
            {token() && (
                <Icon className="trigger" style={{ fontSize: '18px' }} type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={toggle}/>
            )}
          </Col>
          <Col>
            <Menu
              mode="horizontal"
              style={{ lineHeight: "50px" }}
              selectable={false}
            >
              {token() && (
                <Menu.Item>
                  <Link to="/configuracion">Configuración</Link>
                </Menu.Item>
              )}

              {token() && (
                <Menu.Item onClick={handlerClickLogout}>
                  <span>Salir</span>
                </Menu.Item>
              )}
            </Menu>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default AppHeader;
