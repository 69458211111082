import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    Form,
    Icon,
    Input,
    Button,
    Row,
    Col,
    PageHeader,
    Upload,
    message,
    Table,
    Divider,
    AutoComplete,
    Tag,
    message as Message, Select
} from "antd";

import {htmlToText} from "html-to-text";

import * as languageService from  "../../api/services/language.service";
import * as templateService from  "../../api/services/template.service";

const CreateTemplateForm = props => {
    const [submitting, setSubmit] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [locales, setLocales] = useState([]);


    useEffect(() => {
        getLanguages();
    }, []);

    const getLanguages = async () => {
        try {
            const result = await languageService.list();
            setLocales(result.data);
            setLoading(false);
        } catch (e) {

        }
    };

    const beforeUpload = (file) => {
        const arr = file.name.split(".");
        const ext = arr[arr.length - 1].toLowerCase();
        props.form.setFieldsValue({
            file: null,
            text: ''
        });

        if (ext !== "html") {
            message.error("Archivo no disponible. Solo con extensión .html");
            return;
        }

        fillData(file).then(result => {
            const text = htmlToText(result, {
                wordwrap: false
            });

            props.form.setFieldsValue({
                text: text
            });
        });

        setFileList([file]);

        return false;
    };

    const fillData = (file) => {
        const reader = new FileReader();
        return new Promise(resolve => {
            reader.onload = (event) => {
                let data = reader.result;
                resolve(data);
            };
            reader.readAsText(file);
        });
    };

    const onRemove = () => {
        setFileList([]);
        props.form.setFieldsValue({
            file: null,
            text: ''
        });
        return {
            fileList: []
        };
    };

    const handleSubmit = e => {
        e.preventDefault();

        if(fileList.length <= 0){
            props.form.setFieldsValue({
                file: null
            });
        }
        props.form.validateFields(async (err, values) => {
            if (!err) {

                setSubmit(true);
                try{
                    const formData = new FormData();
                    Object.keys(values).map(v => {
                        if(v !=="file") {
                            formData.append(v, values[v]);
                        }
                    });
                    formData.append("file", fileList[0]);

                    await templateService.create(formData);
                    setSubmit(false);
                    props.history.push("/templates/list");
                } catch (e) {
                    setSubmit(false);
                }

            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
        <PageHeader
            onBack={() => null}
            title="Agregar Plantilla"
            backIcon={null}
        />

        <Form onSubmit={handleSubmit} className="template-form" autoComplete="off">
            <Row gutter={16}>
                <Col md={8}>
                    <Form.Item required label="Nombre" style={{ marginBottom: 0 }} />
                    <Form.Item>
                        {getFieldDecorator("name", {
                            rules: [
                                { required: true, message: "El campo Nombre es requerido" }
                                ]
                        })(
                            <Input placeholder="Nombre" />
                         )
                        }
                        </Form.Item>
                </Col>

                <Col md={8}>
                    <Form.Item required label="Idioma" style={{ marginBottom: 0 }} />
                    <Form.Item>
                        {getFieldDecorator("locale", {
                            rules: [
                                { required: true, message: "El campo idioma es requerido" }
                            ]
                        })(
                            <Select
                                    loading={loading}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione una idioma"
                            >
                                {locales.map(locale => (
                                    <Select.Option key={locale.name} value={locale.country_locale_code}>
                                        {locale.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col md={8}>
                    <Form.Item required label="Subject" style={{ marginBottom: 0 }} />
                    <Form.Item>
                        {getFieldDecorator("subject", {
                            rules: [
                                { required: true, message: "El campo Subject es requerido" }
                                ]
                        })(
                            <Input placeholder="Subject" />
                           )
                        }
                        </Form.Item>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col md={16}>
                    <Form.Item  label="Text por defecto" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("text", {
                            })(
                                <Input.TextArea autoSize={{ minRows: 6}} placeholder="Texto por defecto"/>
                            )}
                        </Form.Item>
                    </Col>

                <Col md={8}>
                    <Form.Item label="Archivo HTML" extra="" style={{marginBottom: 4}}>
                        {getFieldDecorator("file", {
                            rules: [
                                { required: true, message: "Seleccione un archivo" }
                            ]
                        })(<Upload beforeUpload={beforeUpload} onRemove={onRemove}
                                   fileList={fileList} accept={"text/*"} >

                            <Button loading={submitting}>
                                <Icon type="upload"/> Clic para subir </Button> </Upload> )}
                    </Form.Item>
                </Col>
           </Row>
            <Form.Item className="text-center">
                <Button type="primary" htmlType="submit" size="large" loading={submitting} >
                    Crear
                </Button>
            </Form.Item>
        </Form>
    </>
    );

};

export default withRouter(
    Form.create({ name: "template_create_form" })(CreateTemplateForm)
);
