import React from "react";
import { withRouter } from "react-router-dom";
import { PageHeader } from "antd";
import token from "../../api/token";
import ConfiguraionGestorForm from "./configuracion/ConfiguracionGestorForm";
import ConfiguracionOutreachForm from "./configuracion/ConfiguracionOutreachForm";

const ConfiguracionForm = () => {
  if (!token()) {
    window.location.href = "/";
    return null;
  }

  return (
    <>
      <PageHeader onBack={() => null} title="Configuración" backIcon={null} />

      {token().rol == "gestor" ? (
        <ConfiguraionGestorForm />
      ) : (
        <ConfiguracionOutreachForm />
      )}
    </>
  );
};

export default withRouter(ConfiguracionForm);
