import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Table, Divider, PageHeader, Popconfirm, Col, Row, Button} from "antd";
import * as templateService from  "../../api/services/template.service";

const Page = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        loadTemplates();

    }, []);

    const loadTemplates = async () => {
        try {
            const result = await templateService.list();
            if (result.data.Data) {
                populate(result.data.Data);
            }
        } catch (err) {

        }
    };

    const onConfirm = async id => {
        await templateService.remove(id);
        loadTemplates();
    };

    const columns = [
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Acción",
            key: "action",
            render: (text, record) => (
                <span>
                    <Link to={`/templates/edit/${record.key}`}>Editar</Link>
                    <Divider type="vertical"/>

                    <Popconfirm
                        title="Esta seguro?"
                        okText="Sí"
                        cancelText="No"
                        onConfirm={() => onConfirm(record.key)}
                    >
                        <a>Eliminar</a>
                    </Popconfirm>
                </span>
            )
        }
    ];

    const populate = list => {
        let items = [];

        list.map(item => {
            items.push({
                key: item.ID,
                name: item.Name
            });
        });

        setData(items);
    };

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Plantillas"
                backIcon={null}
                subTitle="Listado de Plantillas de Emails"
            />
            <Row gutter={16}>
                <Col md={24}>
                    <Link to="/templates/create" style={{ float: 'right' }}>
                    <Button type="primary">
                        Crear plantilla
                    </Button>
                    </Link>
                </Col>
                <Col md={24}>
                    <Table columns={columns} dataSource={data} />
                </Col>
            </Row>
        </>
    );
};

export default Page;
