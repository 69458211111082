import Cliente from "../Cliente";

export const list = (params = {}) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}mailings/`, {params: params});
};

export const get = (id, params = {}) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}mailings/${id}`, {params: params});
};

export const stopSendingEmail = (id, values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}mailings/${id}/stop`, {
            values
        });
};

export const importContact = (id, values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}mailings/${id}/import-contact`, {
            values
        });
};

export const getInboundEmails = (id, params = {}) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}mail/inbox`, {params: params});
};

export const getInboundEmail = (id) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}mail/inbox/${id}`);
};

export const replyEmail = (id, values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}mail/inbox/${id}/reply`, {
            values
        });
};