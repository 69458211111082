import React, { useEffect, useState } from "react";
import { Form, Icon, Input, Button, Row, Col, Popconfirm } from "antd";
import Cliente from "../../../api/Cliente";
import * as configService from "../../../api/services/config.service";

let id = 0;

const ConfiguracionOutreachForm = props => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledAdd, setDisabledAdd] = useState(true);
  const [accountLinks, setAccountLinks] = useState([]);
  const { getFieldDecorator, getFieldValue } = props.form;
  const [usersExists, setUsersExists] = useState([]);

  useEffect(() => {
    loadConfig();
  }, []);

  const remove = k => {
    const { form } = props;
    const keys = form.getFieldValue("keys");

    if (keys.label === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });

    setDisabled(form.getFieldValue("keys").length === 0);
  };

  const clear = () => {
    const { form } = props;
    form.setFieldsValue({
      keys: []
    });
  };

  const add = () => {
    const { form } = props;

    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);

    form.setFieldsValue({
      keys: nextKeys
    });

    setDisabled(false);
  };

  const loadConfig = async () => {
    const result = await configService.get();

    const config = result.data;
    setAccountLinks(config.children);
    setUsersExists(config.users);

    // setDisabledAdd(config.parent !== 0);
    setDisabledAdd(false);
  };

  const removeAccount = async id => {
    setLoading(true);
    try {
      const result = await Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}config/remove/${id}`);

      await loadConfig();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const addExistentUser = async id => {
    setLoading(true);
    try {
      await Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}config/add-existent/${id}`);

      await loadConfig();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        try {
          // console.log(values);

          // return;
          const { accounts } = values;

          const result = await Cliente.setBearerToken()
            .getCliente()
            .post(`${process.env.REACT_APP_API_URL}config`, {
              // accounts: values.names.filter(name => name != null)
              accounts
            });

          clear();
          setAccountLinks(result.data);
        } catch (err) {
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 }
    }
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 }
    }
  };

  const forItemLayout = {
    wrapperCol: {
      span: 24,
      offset: 0
    }
  };

  const formItems = () => {
    getFieldDecorator("keys", {
      initialValue: []
    });

    const keys = getFieldValue("keys");

    return keys.map((k, index) => (
      <React.Fragment key={k}>
        <Row gutter={3}>
          <Col md={8}>
            <Form.Item
              // label={index === 0 ? "Cuentas" : ""}
              {...forItemLayout}
              label={"Correo"}
              required={false}
            >
              {getFieldDecorator(`accounts[${k}][email]`, {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    whitespace: false,
                    message: "Ingrese un correo o elimine este campo"
                  },
                  {
                    type: "email",
                    message: "El campo no es correo valido"
                  }
                ]
              })(
                <Input
                  placeholder="Correo"
                  style={{ width: "80%", marginRight: 8 }}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item {...forItemLayout} label="Nombre" required={true}>
              {getFieldDecorator(`accounts[${k}][name]`, {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    whitespace: false,
                    message: "Ingrese un nombre para este campo"
                  }
                ]
              })(
                <Input
                  placeholder="Nombre"
                  style={{ width: "80%", marginRight: 8 }}
                />
              )}
            </Form.Item>
          </Col>

          <Col md={8}>
            <Form.Item {...forItemLayout} label="Apellidos" required={true}>
              {getFieldDecorator(`accounts[${k}][lastname]`, {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    whitespace: false,
                    message: "Ingrese un apellido para este campo"
                  }
                ]
              })(
                <Input
                  placeholder="Apellidos"
                  style={{ width: "80%", marginRight: 8 }}
                />
              )}

              <Icon
                className="dynamic-delete-button"
                type="minus-circle-o"
                onClick={() => remove(k)}
              />
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    ));
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="login-form" autoComplete="off">
        <Row gutter={16}>
          <Col md={14}>
            <h4>Agregar cuentas</h4>
            {formItems()}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button
                type="dashed"
                onClick={add}
                style={{ width: "60%" }}
                disabled={disabledAdd}
              >
                <Icon type="plus" /> Agregar cuenta de correo
              </Button>
            </Form.Item>
          </Col>
          <Col md={10}>
            <div style={{ paddingLeft: 40 }}>
              <h4>Cuentas enlazadas</h4>
              <ul className="list-custom">
                {accountLinks.map(account => (
                  <li key={account.id} className="mb">
                    <div>
                      <span>{account.name}</span>
                      <span>{account.lastname}</span>
                      <span>{account.email}</span>
                      <Popconfirm
                        title="Está seguro que desea desvincular esta cuenta？"
                        okText="Sí, estoy seguro"
                        cancelText="No"
                        okType="danger"
                        onConfirm={e => removeAccount(account.id)}
                      >
                        <Button
                          type="danger"
                          size="default"
                          style={{ marginLeft: 10 }}
                        >
                          Quitar
                        </Button>
                      </Popconfirm>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={14} style={{ marginBottom: 50 }}>
            <h4>Agregar cuenta de usuario existente</h4>
            <ul className="list-custom">
              {usersExists.map(usersExists => (
                <li key={usersExists.id} className="mb">
                  <div>
                    <span>{usersExists.name}</span>
                    <span>{usersExists.lastname}</span>
                    <span>{usersExists.email}</span>
                    <Popconfirm
                      title="Está seguro que desea vincular esta cuenta？"
                      okText="Sí, estoy seguro"
                      cancelText="No"
                      onConfirm={e => addExistentUser(usersExists.id)}
                    >
                      <Button
                        type="link"
                        size="default"
                        style={{ marginLeft: 10 }}
                      >
                        Agregar
                      </Button>
                    </Popconfirm>
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>

        <Form.Item className="text-center">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            disabled={disabled}
          >
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Form.create({ name: "config_form" })(ConfiguracionOutreachForm);
