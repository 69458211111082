import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Row, Col, PageHeader, Table, Icon, Spin, Button, Descriptions, message} from "antd";
import moment from "moment";
import * as twitterService from  "../../../api/services/twitter.service";
import SearchForm from "./search";


const Page = () => {
    const [userTable, setUserTable] = useState({
        data: [],
        loading: false,
        paginationToken: ''
    });
    const [hasMoreItems, setHasMoreItems] = useState("");
    const [searchParams, setSearchParams] = useState({
        username: "",
        type:"",
    });
    const [spinState, setSpinState] = useState(false);

    useEffect(() => {
    }, []);

    const handleSearch = async (values) => {
        setSearchParams({
            username: values.username,
            type: values.filterType,
        });
        setUserTable({
            data: [],
            loading: false,
            paginationToken: ''
        });
        await search(values.username, values.filterType);
    };

    const search = async (username,filterType,  params = {}) => {
        try {
            let firstLoad = false;

            setUserTable({
                loading: true
            });
           // setSpinState(true);

            if(!params.limit){
                params.limit = 100;
                firstLoad = true;
            }

            if(!firstLoad && userTable.paginationToken){
                console.log('setting pagination');
                params.paginationToken = userTable.paginationToken;
            }

            let result = [];
            if(filterType === 'followers') {
                result = await twitterService.getFollowersByUsername(username, params);
            } else {
                result = await twitterService.getFollowingByUsername(username, params);
            }
            console.log(result);

            let pagination = null;
            let items = [];
            if(result && result.data.meta && result.data.meta.result_count > 0){
                result.data.data.map(item => {
                    let entity = null;
                    if(item.entities && item.entities.url && item.entities.url.urls){
                        if(item.entities.url.urls.length > 0){
                            entity =  item.entities.url.urls[0];
                        }
                    }
                    items.push({
                        key: item.id,
                        username:item.username,
                        name:item.name,
                        location: item.location,
                        entity: entity
                    });
                });
                if (result.data.meta.next_token) {
                    pagination = result.data.meta.next_token
                }
            } else if(result.data.error) {
                if (result.data.error.title === 'User Not Found'){
                    message.info("Usuario no encontrado");
                }
            }

            // for(let i =0; i<10; i++){
            //     items.push({
            //                     key: i,
            //                     username:`username${i}`,
            //                     name:`Name${i}`,
            //                 });
            // }

            let data = [];
            if(firstLoad){
                data = items;
            } else {
               data = [...userTable.data, ...items];
            }

            setUserTable({
                loading: false,
                data: data,
                paginationToken: pagination
            });
            // setSpinState(false);
            console.log(pagination);
            if(pagination !== null) {
                console.log('show load more');
                setHasMoreItems(true);
            } else {
                console.log('hide load more');
                setHasMoreItems(false);
            }
        } catch (e) {
            message.error("Ha ocurrido un error");
            console.log(e)
        }
    };

    const addScrollEvent = () => {
        const tableContent = document.querySelector('.ant-table-body');

        tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight;
            let currentScroll = event.target.scrollTop;
            console.log(currentScroll);
            console.log(maxScroll)
            if (currentScroll === maxScroll) {
                console.log('loadData');
                // load more data
                const newData = [];
                for (let i = 0; i < 5; i++) {
                    newData.push({
                        key: i,
                        name: `Wen ${i}`,
                        username: `Tokyo, Japan. ${i}`,
                    });
                }
                let data = userTable.data;
                data = data.concat(newData);

                setUserTable({
                    loading: false,
                    data,
                });

            }
        })

    }


    const handleUserTableChange = async () =>{
       await search(searchParams.username, searchParams.type, {
            limit: 100,
            paginationToken: userTable.paginationToken
        });

        // console.log(userTable);
        // let dataItems = userTable.data;
        // if(dataItems.length >= 20){
        //     console.log(`table length ${dataItems.length}`);
        //     setHasMoreItems(false);
        // }else {
        //     setUserTable({
        //         loading: true,
        //     });
        //     let newData = [];
        //     await Promise.all(
        //         Array(5)
        //             .fill(null)
        //             .map(async (v, idx) => {
        //                 const val = dataItems.length + idx + 1;
        //                 newData.push({
        //                     key: val,
        //                     name: `Wen ${val}`,
        //                     username: `Tokyo, Japan. ${val}`,
        //                 });
        //             })
        // )
        //     ;
        //     console.log(newData);
        //     const data = [...dataItems, ...newData];
        //     console.log(data);
        //     if(data.length >= 20){
        //         setHasMoreItems(false);
        //     }
        //     setUserTable({
        //         loading: false,
        //         data: data,
        //     });
        //     console.log(userTable);
        // }


    };

    const columns = [
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Usuario",
            dataIndex: "username",
            key: "username",
            width: 250,
        },
        {
            title: "Dirección",
            dataIndex: "location",
            key: "location",
            width: 250,
        },
        // {
        //     title: "Acción",
        //     key: "action",
        //     render: (text, record) => (
        //         <span>
        //             <Link to={`/pitchbox/emails/inbox/${record.key}`}>
        //                 <Icon type="eye" style={{ paddingRight: 5 }}/>
        //                 Ver
        //             </Link>
        //         </span>
        //     )
        // }
    ];

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Buscar seguidores"
                backIcon={null}
            />
            <Spin spinning={spinState}>
            <SearchForm onSearch={handleSearch}/>
            <Row gutter={16}>
                <Col md={24} style={{marginTop: 20}}>
                    <Table columns={columns}
                           dataSource={userTable.data}
                           loading={userTable.loading}
                           pagination={false}
                           expandedRowRender={record =>
                               <div>
                               <Descriptions title="Detalles"  column={1}>
                                   <Descriptions.Item label="Dominio">{record.entity !== null ? record.entity.display_url : '-'}</Descriptions.Item>
                                   <Descriptions.Item label="Url">{record.entity !== null ? record.entity.expanded_url : '-'}</Descriptions.Item>
                               </Descriptions>
                               </div>
                            }
                           />
                </Col>
                {hasMoreItems === true &&
                <Col md={24}>
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: 12,
                            height: 32,
                            lineHeight: '32px',
                        }}
                    >
                        <Button onClick={handleUserTableChange}>Cargar más</Button>
                    </div>
                </Col>
                }

            </Row>
            </Spin>
        </>
    );
};

export default Page;
