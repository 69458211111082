import React, { useEffect, useState } from "react";

import {
    Form,
    Icon,
    Input,
    Button,
    Row,
    Col,
    PageHeader,
    message,
    Table,
    AutoComplete,
    Drawer, Select, Typography,
    Spin,
    DatePicker
} from "antd";

const SearchForm = props => {

    const [tLDFilterType, setTLDFilterType] = useState([]);
    const [topLevelDomains, setTopLevelDomains] = useState([]);
    const [dateFilterType, setDateFilterType] = useState([]);
    const [dateString, setDateString] = useState("");

    useEffect(() => {
        getTLDFilterType();
        getTLDList();
        getDateFilterType();
    }, []);


    const getTLDFilterType = () => {
        const filterType = [
            {
                id: 'include',
                name: 'Incluir'
            }, {
                id: 'exclude',
                name: 'Excluir'
            }
        ]
        setTLDFilterType(filterType);
    }

    const getTLDList = () => {
        const tld = [
            {name: '.com'}, {name: '.org'}, {name: '.int'}, {name: '.edu'},
            {name: '.gov'}, {name: '.mil'}, {name: '.ad'},  {name: '.ae'},
            {name: '.af'},  {name: '.ag'},  {name: '.ao'},  {name: '.ar'},
            {name: '.at'},  {name: '.au'},  {name: '.aw'},  {name: '.ax'},
            {name: '.bb'},  {name: '.be'},  {name: '.bn'},  {name: '.bo'},
            {name: '.br'},  {name: '.bs'},  {name: '.ca'},  {name: '.cd'},
            {name: '.cl'},  {name: '.cm'},  {name: '.co'},  {name: '.cr'},
            {name: '.cu'},  {name: '.cz'},  {name: '.de'},  {name: '.dk'},
            {name: '.ec'},  {name: '.ee'},  {name: '.eg'},  {name: '.es'},
            {name: '.eu'},  {name: '.fi'},  {name: '.fr'},  {name: '.ge'},
            {name: '.gf'},  {name: '.gr'},  {name: '.gt'},  {name: '.gw'},
            {name: '.gy'},  {name: '.hk'},  {name: '.hn'},  {name: '.hr'},
            {name: '.hu'},  {name: '.id'},  {name: '.ie'},  {name: '.il'},
            {name: '.in'},  {name: '.ir'},  {name: '.it'},  {name: '.jm'},
            {name: '.jp'},  {name: '.kp'},  {name: '.kr'},  {name: '.mx'},
            {name: '.ni'},  {name: '.nl'},  {name: '.nz'},  {name: '.pa'},
            {name: '.pe'},  {name: '.pl'},  {name: '.pr'},  {name: '.pt'},
            {name: '.py'},  {name: '.qa'},  {name: '.ro'},  {name: '.rs'},
            {name: '.ru'},  {name: '.sa'},  {name: '.se'},  {name: '.sg'},
            {name: '.tr'},  {name: '.uk'},  {name: '.us'},  {name: '.uy'},
            {name: '.ve'},  {name: '.vn'},  {name: '.za'},  {name: '.my'},
            {name: '.no'},  {name: '.ph'},  {name: '.ch'},  {name: '.th'},
            {name: '.ua'},  {name: '.net'}
        ];

        setTopLevelDomains(tld);
    }

    const getDateFilterType = () => {
        const dateFilterType = [
            {
                id: 'mayor',
                name: 'Mayor o igual'
            }, {
                id: 'minor',
                name: 'Menor o igual'
            }
        ]
        setDateFilterType(dateFilterType);
    }

    const onCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const onChangeDate = (date, dateString) => {
        setDateString(dateString);
    }

    const handleFilter = e => {
        const values = {
            filter_tld_type: props.form.getFieldValue('tld_filter_type'),
            filter_tld: props.form.getFieldValue('tld'),
            filter_date_type: props.form.getFieldValue('date_filter_type'),
            filter_date: dateString
        };

        if (props.onFilter) {
            props.onFilter(values);
        }
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
            <Form autoComplete="off">
                <Row gutter={16}>
                    <Col md={24}>
                            <Row gutter={16}>
                                <Col md={12}>
                                    <Form.Item label="Acción" style={{ marginBottom: 0 }} />
                                    <Form.Item>
                                        {getFieldDecorator("tld_filter_type", {
                                        })(
                                            <Select
                                                allowClear
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="Incluya o excluya tld"
                                            >
                                                {tLDFilterType.map(ft => (
                                                    <Select.Option key={ft.id} value={ft.id}>
                                                        {ft.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={12}>
                                    <Form.Item label="Dominio de primer nivel(TLD)" style={{ marginBottom: 0 }} />
                                    <Form.Item>
                                        {getFieldDecorator("tld", {
                                        })(
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="Seleccione los dominios"
                                            >
                                                {topLevelDomains.map(tld => (
                                                    <Select.Option key={tld.name} value={tld.name}>
                                                        {tld.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={12}>
                                    <Form.Item label="Acción" style={{ marginBottom: 0 }} />
                                    <Form.Item>
                                        {getFieldDecorator("date_filter_type", {
                                        })(
                                            <Select
                                                allowClear
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="Selecione una opción"
                                            >
                                                {dateFilterType.map(df => (
                                                    <Select.Option key={df.id} value={df.id}>
                                                        {df.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col md={12}>
                                    <Form.Item label="Fecha" style={{ marginBottom: 0 }} />
                                    <Form.Item>
                                        {getFieldDecorator("date", {
                                        })(
                                            <DatePicker format="YYYY-MM-DD" onChange={onChangeDate}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Button onClick={onCancel} style={{ marginRight: 8 }}>
                                    Cancelar
                                </Button>
                                <Button onClick={handleFilter} type="primary">
                                    Aplicar
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default Form.create({ name: "backlink_search_form" })(SearchForm);