import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Divider, Popconfirm } from "antd";
import * as emailService from "../../api/services/outreacher_email.service";

const Page = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    loadWebs();
  }, []);

  const loadWebs = async () => {
    try {
      const result = await emailService.list();
      populate(result.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onConfirm = async id => {
    await emailService.destroy(id);

    loadWebs();
  };

  const columns = [
    {
      title: "email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Accion",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/emails/editar/${record.key}`}>Editar</Link>
          <Divider type="vertical" />

          <Popconfirm
            title="Esta seguro?？"
            okText="Sí"
            cancelText="No"
            onConfirm={() => onConfirm(record.key)}
          >
            <a>Eliminar</a>
          </Popconfirm>
        </span>
      )
    }
  ];

  const populate = _data => {
    let d = [];

    _data.map(item => {
      d.push({
        key: item.id,
        email: item.email
      });
    });

    setData(d);
  };

  return (
    <>
      <Table columns={columns} dataSource={data} />
    </>
  );
};

export default Page;
