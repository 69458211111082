import Cliente from "../Cliente";

export const profile = () => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}user/profile`);
};

export const getAll = () => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}user/`);
};

export const getByRol = rol => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}user/rol/${rol}`);
};

export const show = id => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}user/${id}`);
};

export const destroy = id => {
  return Cliente.setBearerToken()
    .getCliente()
    .delete(`${process.env.REACT_APP_API_URL}user/${id}`);
};
