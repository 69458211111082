import React from "react";
import { Route } from "react-router-dom";
import _ from "lodash";
import token from "./api/token";

class RouteRol extends Route {
  constructor(props) {
    super(props);

    this.roles = _.isArray(props.rol) ? props.rol : [props.rol];
  }

  render() {
    const { page404 } = this.props;

    if (!token()) return null;

    if (_.find(this.roles, e => e === token().rol)) {
      return <Route {...this.props}>{this.props.children}</Route>;
    } else {
      return page404 ? React.cloneElement(page404, this.props) : null;
    }
  }
}

export default RouteRol;
