import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import {List, Typography, Card, Row, Col, PageHeader, Switch, Button, Select, Icon} from "antd";
import { BarChart, Bar, Cell, YAxis, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from "moment";
import token from "../api/token";
import * as dashboardService from "../api/services/dashboard.service";
import ContactsTable from "./contacts/contacts";
import * as SVGIcon from "../components/SVGIcon";

const Page = props => {
  const tok = token();
  const [notifications, setNotifications] = useState([]);
  const [emailsRead, setEmailsRead] = useState([]);
  const [loadingCampaignsReport, setLoadingCampaignsReport] = useState(true);
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignsFrequency, setCampaignsFrequency] = useState({
      selectedFrequency : "",
      frequencyOptions: []
  });

  const [loadingSentEmailsReport, setLoadingSentEmailsReport] = useState(true);
  const [sentEmailsData, setSentEmailsData] = useState([]);
  const [sentEmailsFrequency, setSentEmailsFrequency] = useState({
      selectedFrequency : "",
      frequencyOptions: []
  });

  const [loadingReceivedEmailsReport, setLoadingReceivedEmailsReport] = useState(true);
  const [receivedEmailsData, setReceivedEmailsData] = useState([]);
  const [receivedEmailsFrequency, setReceivedEmailsFrequency] = useState({
        selectedFrequency : "",
        frequencyOptions: []
  });
  const [singleSummary, setSingleSummary] = useState(true);
  const [singleSummaryClass, setSingleSummaryClass] = useState("i-selected-item");
  const [groupSummaryClass, setGroupSummaryClass] = useState("");
  const ContactsIcon = props => <Icon component={SVGIcon.ContactsIcon()} {...props} />;

  useEffect(() => {
    if (!tok) {
      props.history.push("/login");
    } else {
      // if (tok.rol === "gestor") {
      //   getEmailsRead();
      // }
        if (tok.rol !== "gestor") {
            setCampaignsFrequencyData();
            setSentEmailsFrequencyData();
            setReceivedEmailsFrequencyData();

        } else {
            getNotifications();
        }
    }
  }, []);

    React.useEffect(() => {
        reloadData();
    }, [singleSummary]);

    const getNotifications = async () => {
        const ntfs = await dashboardService.getNotifications();

        setNotifications(ntfs.data.notifications);
    };

    const getEmailsRead = async () => {
        const emails = await dashboardService.getEmailsRead();

        setEmailsRead(emails.data);
    };

    //campaigns
    const setCampaignsFrequencyData = () => {
        setCampaignsFrequency({
            selectedFrequency: 'semanal',
            frequencyOptions: ['semanal', 'mensual']
        });
    };

    const handleChangeCampaignsFrequency = (e) => {
        setCampaignsFrequency({
            selectedFrequency: e,
            frequencyOptions: ['semanal', 'mensual']
        });

        getCampaignsReport(e);
    };

    const getCampaignsReport = async (frequency) => {
        setLoadingCampaignsReport(true);
        try {
            const now = moment();
            const currentFrequency = (frequency) ? frequency : 'semanal';
            let startDate;
            let endDate;
            if(currentFrequency === 'semanal') {
                startDate = now.clone().weekday(1).format('YYYY-MM-DD');
                endDate = now.clone().weekday(7).format('YYYY-MM-DD');
            } else {
                startDate = now.clone().startOf('month').format('YYYY-MM-DD');
                endDate = now.clone().endOf('month').format('YYYY-MM-DD');
            }

            const p = {
                'frequency': (currentFrequency === 'semanal') ? 'weekly' : 'monthly',
                startDate: startDate,
                endDate: endDate,
                multiple: !singleSummary,
            };

            const result = await dashboardService.getCampaignsReport(p);

            setCampaignsData(result.data);
            setLoadingCampaignsReport(false);
        } catch (e) {
        }
    };

    //sent
    const setSentEmailsFrequencyData = () => {
        setSentEmailsFrequency({
            selectedFrequency: 'semanal',
            frequencyOptions: ['semanal', 'mensual']
        });
    };

    const handleChangeSentEmailsFrequency = (e) => {
        setSentEmailsFrequency({
            selectedFrequency: e,
            frequencyOptions: ['semanal', 'mensual']
        });

        getSentEmailsReport(e);
    };

    const getSentEmailsReport = async (frequency) => {
        setLoadingSentEmailsReport(true);
        try {
            const now = moment();
            const currentFrequency = (frequency) ? frequency : 'semanal';
            let startDate;
            let endDate;
            if(currentFrequency === 'semanal') {
                startDate = now.clone().weekday(1).format('YYYY-MM-DD');
                endDate = now.clone().weekday(7).format('YYYY-MM-DD');
            } else {
                startDate = now.clone().startOf('month').format('YYYY-MM-DD');
                endDate = now.clone().endOf('month').format('YYYY-MM-DD');
            }

            const p = {
                'frequency': (currentFrequency === 'semanal') ? 'weekly' : 'monthly',
                startDate: startDate,
                endDate: endDate,
                multiple: !singleSummary,
            };

            const result = await dashboardService.getSentEmailsReport(p);

            setSentEmailsData(result.data);
            setLoadingSentEmailsReport(false);
        } catch (e) {
        }
    }

    //inbounds
    const setReceivedEmailsFrequencyData = () => {
        setReceivedEmailsFrequency({
            selectedFrequency: 'semanal',
            frequencyOptions: ['semanal', 'mensual']
        });
    };

    const handleChangeReceivedEmailsFrequency = (e) => {
        setReceivedEmailsFrequency({
            selectedFrequency: e,
            frequencyOptions: ['semanal', 'mensual']
        });

        getReceivedEmailsReport(e);
    };

    const getReceivedEmailsReport = async (frequency) => {
        setLoadingReceivedEmailsReport(true);
        try {
            const now = moment();
            const currentFrequency = (frequency) ? frequency : 'semanal';
            let startDate;
            let endDate;
            if(currentFrequency === 'semanal') {
                startDate = now.clone().weekday(1).format('YYYY-MM-DD');
                endDate = now.clone().weekday(7).format('YYYY-MM-DD');
            } else {
                startDate = now.clone().startOf('month').format('YYYY-MM-DD');
                endDate = now.clone().endOf('month').format('YYYY-MM-DD');
            }

            const p = {
                'frequency': (currentFrequency === 'semanal') ? 'weekly' : 'monthly',
                startDate: startDate,
                endDate: endDate,
                multiple: !singleSummary
            };

            const result = await dashboardService.getReceivedEmailsReport(p);
            setReceivedEmailsData(result.data);
            setLoadingReceivedEmailsReport(false);
        } catch (e) {
        }
    }


    const titleHeader = () => {
        return tok.rol === "gestor"
            ? "Artículos sin completar por los Outreach"
            : "Panel de Control";
    }

    const toggleSummaryType = (isChecked) => {
        if (isChecked) {
            setSingleSummaryClass("i-selected-item");
            setGroupSummaryClass("");
            setSingleSummary(true);
        } else {
            setSingleSummaryClass("");
            setGroupSummaryClass("i-selected-item");
            setSingleSummary(false);
        }
    };

    const reloadData = (isChecked) => {
        getCampaignsReport(campaignsFrequency.selectedFrequency);
        getSentEmailsReport(sentEmailsFrequency.selectedFrequency);
        getReceivedEmailsReport(receivedEmailsFrequency.selectedFrequency);
    };

  if (!tok) return null;

  return (
    <>
        <PageHeader
            ghost={false}
            onBack={() => null}
            title={titleHeader()}
            backIcon={null}
            extra={[
                <div key="groupSummaryIcon">
                    <ContactsIcon className={groupSummaryClass} style={{ fontSize: '25px' }}/>
                    <Switch checked={singleSummary} onChange={toggleSummaryType} style={{marginLeft: "15px", marginRight: "15px"}}/>
                    <Icon type="user" className={singleSummaryClass} style={{ fontSize: '22px' }}/>
                </div>
            ]}
        />

        {tok.rol === "gestor" && (
      <List
        header={
          <div>
            <h1>{titleHeader()}</h1>
          </div>
        }
        bordered
        dataSource={notifications}
        renderItem={notification => (
          <List.Item>
            <Typography.Text strong style={{ marginRight: 10 }}>
              {notification.title}
            </Typography.Text>
            <Typography.Text ellipsis style={{ marginRight: 10 }}>
              {notification.medio}
            </Typography.Text>
            <Link to={`/outreachfunnel/${notification.id}`}>
              Ir al artículo
            </Link>
          </List.Item>
        )}
      />
        )}

        {tok.rol !== "gestor" && (
        <Row gutter={16}>
            <Col md={8}>
                <Card loading={loadingCampaignsReport}>
                    <Row gutter={16}>
                        <Col md={12}>
                            <h3>Campañas</h3>
                        </Col>
                        <Col md={12}>
                            <Select className="float-right" style={{width: 120}}
                                    defaultValue={campaignsFrequency.selectedFrequency}
                                    optionFilterProp="children"
                                    onChange={handleChangeCampaignsFrequency}
                            >
                                {campaignsFrequency.frequencyOptions.map(op => (
                                    <Select.Option key={op} value={op}>
                                        {op.toUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col md={24}>
                            <ResponsiveContainer width="100%" height={200}>
                                <BarChart
                                    data={campaignsData}
                                    margin={{
                                        top: 20,
                                        right: -20,
                                        left: -20,
                                        bottom: 5,
                                    }}
                                >
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Bar name="Actual" dataKey="uv" fill="#3092F9"/>
                                    <Bar name="Anterior" dataKey="pv" fill="#1E64AC"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col md={8}>
                <Card loading={loadingSentEmailsReport}>
                    <Row gutter={16}>
                        <Col md={8}>
                            <h3>Envíos</h3>
                        </Col>
                        <Col md={16}>
                            <Select className="float-right" style={{width: 120}}
                                    defaultValue={sentEmailsFrequency.selectedFrequency}
                                    optionFilterProp="children"
                                    onChange={handleChangeSentEmailsFrequency}
                            >
                                {sentEmailsFrequency.frequencyOptions.map(op => (
                                    <Select.Option key={op} value={op}>
                                        {op.toUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col md={24}>
                            <ResponsiveContainer width="100%" height={200}>
                                <BarChart
                                    data={sentEmailsData}
                                    margin={{
                                        top: 20,
                                        right: -20,
                                        left: -20,
                                        bottom: 5,
                                    }}
                                >
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Bar name="Actual" dataKey="uv" fill="#48CFAD"/>
                                    <Bar name="Anterior" dataKey="pv" fill="#37BC9B"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col md={8}>
                <Card loading={loadingReceivedEmailsReport}>
                    <Row gutter={16}>
                        <Col md={8}>
                            <h3>Recibidos</h3>
                        </Col>
                        <Col md={16}>
                            <Select className="float-right" style={{width: 120}}
                                    defaultValue={receivedEmailsFrequency.selectedFrequency}
                                    optionFilterProp="children"
                                    onChange={handleChangeReceivedEmailsFrequency}
                            >
                                {receivedEmailsFrequency.frequencyOptions.map(op => (
                                    <Select.Option key={op} value={op}>
                                        {op.toUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col md={24}>
                            <ResponsiveContainer width="100%" height={200}>
                                <BarChart
                                    data={receivedEmailsData}
                                    margin={{
                                        top: 20,
                                        right: 0,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                >
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Bar name="Actual" dataKey="uv" fill="#FEA734"/>
                                    <Bar name="Anterior" dataKey="pv" fill="#FE6E0E"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        )}
        {tok.rol !== "gestor" && (
            <Row gutter={16}>
                <Col md={24} style={{marginTop: 20}}>
                    <Card>
                        <h3>Conseguidos</h3>
                        <ContactsTable/>
                    </Card>
                </Col>
            </Row>
        )}
      {/* {tok.rol === "gestor" && (
        <>
          <Divider />
          <List
            header={
              <div>
                <h1>Artículos leidos por clientes</h1>
              </div>
            }
            bordered
            dataSource={emailsRead}
            renderItem={email => (
              <List.Item>
                <Typography.Text strong style={{ marginRight: 10 }}>
                  {email.medio}
                </Typography.Text>
              </List.Item>
            )}
          />
        </>
      )} */}
    </>
  );
};

export default withRouter(Page);
