import React from "react";
import { withRouter } from "react-router-dom";
import EditTemplateForm from "../templates/edit.form";

const Page = ({ match }) => {
    return (
        <>
            <EditTemplateForm id={match.params.id}/>
        </>
    );
};

export default withRouter(Page);
