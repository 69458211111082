import React, { useEffect, useState } from "react";

import {
    Form,
    Icon,
    Input,
    Button,
    Row,
    Col,
    PageHeader,
    message,
    AutoComplete,
    Select, Typography,
    Spin, Tag, Upload
} from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import * as contactService from  "../../api/services/contact.service";
import * as outreacherEmailService from "../../api/services/outreacher_email.service";
import * as messageService from "../../api/services/message.service";
import * as templateService from  "../../api/services/template.service";

const SendEmailForm = props => {
    const [submitting, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [spinState, setSpinState] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [outreachEmails, setOutreachEmails] = useState([]);
    const [inputVisible, setInputVisible]= useState(false);
    const [inputValue, setInputValue]= useState("");
    let [emails, setEmails] = useState([]);
    const [contactType, setContactType] = useState([]);
    const [selectedContactType, setSelectedContactType] = useState("");
    const [fileList, setFileList] = useState([]);

    const editorModules = {
        toolbar: [
            [{ 'size': ['small', 'normal', 'large'] }],
            [{ 'header': 1 }, { 'header': 2 }],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
        ],
    };

    const editorFormats = [
        'size',
        'header',
        'font',
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent',
        'link'
    ];

    let _emails = [];

    const { Text } = Typography;

    useEffect(() => {
        (async () => {
            try {
                getContactsType();
                setSelectedContactType("manually");
                loadOutreacherEmail();
                getTemplates();
                getMessages();
            } catch (err) {}
        })();
    }, []);

    const getContactsType = () => {
        const contactsType = [
            {
                id: 'manually',
                name: 'Entrar manualmente'
            }, {
                id: 'csv',
                name: 'Cargar desde csv'
            }
        ];
        setContactType(contactsType);
    };

    const handleContactTypeChange = (e) => {
        setSelectedContactType(e);
        setEmails([]);
        setFileList([]);
        props.form.setFields({
            fileList: {
                value: null
            }
        });
    }

    const getTemplates = async () => {
        let list = [];
        try {
            const templatesResponse = await templateService.list();
            if (templatesResponse.data.Count > 0) {
                templatesResponse.data.Data.map(item => {
                    list.push({
                        id: item.ID,
                        name: item.Name
                    });
                });
            }
        } catch (e) {

        }

        setTemplates(list);
        setLoading(false);
    }

    const loadOutreacherEmail = async () => {
        try {
            const result = await outreacherEmailService.list();
            let emails = [];

            result.data.map(outreach => {
                emails.push(outreach.email);
            });

            _emails = [...emails];

            props.form.setFields({
                email: {
                    value: _emails[0]
                }
            });

            setOutreachEmails(emails);
        } catch (e) {
        }
    };

    const getMessages = async () => {
        const result = await messageService.list();

        const messages = result.data;

        props.form.setFields({
            message: {
                value: messages && messages.message1 ? messages.message1 : ""
            },
            subject: {
                value: messages && messages.subject1 ? messages.subject1 : ""
            }
        });
    };

    const validateMessage = () => {
        if(!props.form.getFieldValue('message').replace(/<[^>]*>/g, "").trim()) {
            props.form.setFields({
                message: {
                    value: ""
                },
            })
        }
    };

    const handleCloseEmailTag = removedTag => {
        const tags = emails.filter(tag => tag !== removedTag);
        setEmails(tags);
        props.form.setFieldsValue({
            to: tags.join(','),
        });
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = e => {
        setInputValue((e.target.value));
    };

    const handleInputConfirm = () => {
        if (inputValue && emails.indexOf(inputValue) === -1) {
            emails = [...emails, inputValue];
        }
        setEmails(emails);
        setInputVisible(false);
        setInputValue('');
        props.form.setFieldsValue({
            to: emails.join(','),
        });
    };

    /**
     * Before upload file
     *
     * @param file
     *
     * @returns {boolean}
     */
    const beforeUpload = (file) => {
        const arr = file.name.split(".");
        const ext = arr[arr.length - 1].toLowerCase();
        props.form.setFieldsValue({
            fileList: null
        });

        if (ext !== "csv") {
            message.error("Archivo no disponible. Solo con extensión .csv");
            return;
        }

        setFileList([file]);

        return false;
    };

    /**
     * Remove file
     *
     * @returns {{fileList: []}}
     */
    const onRemove = () => {
        setFileList([]);
        return {
            fileList: []
        };
    };

    const handleSubmit = e => {
        e.preventDefault();

        validateMessage();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                setSubmit(true);
                setSpinState(true);
                try {

                    const formData = new FormData();

                    if (selectedContactType === 'csv') {
                        formData.append("file", fileList[0]);
                    }
                    formData.append("email", props.form.getFieldValue("email"));
                    formData.append("template", props.form.getFieldValue("template"));
                    formData.append("tags", props.form.getFieldValue("to"));
                    formData.append("subject", props.form.getFieldValue("subject"));
                    formData.append("message", props.form.getFieldValue("message"));

                    const result = await contactService.sendSimpleEmail(formData);
                    setSubmit(false);

                    setSpinState(false);

                    if(result.data.success) {
                        message.success(`El email ha sido configurado satisfactoriamente.
                        En breve comenzará a realizarse el envío`);
                    } else {
                        message.info("El email no se ha sido podido enviar");
                    }
                } catch (e) {
                    setSubmit(false);
                    setSpinState(false);
                    message.error("Ha ocurrido un error enviando el email");
                }
            } else {
                message.warning("Por favor, compruebe que todos los campos estén correctos");
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Emails"
                backIcon={null}
                subTitle="Enviar emails"
            />

            <Form onSubmit={handleSubmit} autoComplete="off">
                <Spin spinning={spinState}>
                <Row gutter={16}>
                    <Col md={8}>
                                <Form.Item
                                    required
                                    label="Correo de origen"
                                    style={{ marginBottom: 0 }}
                                />
                                <Form.Item>
                                    {getFieldDecorator("email", {
                                        rules: [
                                            {
                                                type: "email",
                                                message: "El campo no es un correo valido"
                                            },
                                            {
                                                required: true,
                                                message: "El campo Correo de origen es requerido"
                                            }
                                        ]
                                    })(
                                        <AutoComplete
                                            dataSource={outreachEmails}
                                            filterOption={(inputValue, option) =>
                                                option.props.children
                                                    .toLowerCase()
                                                    .indexOf(inputValue.toLowerCase()) !== -1
                                            }
                                            prefix={
                                                <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                                            }
                                            placeholder="Correo de origen"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                    <Col md={8}>
                                <Form.Item required label="Plantilla" style={{ marginBottom: 0 }} />
                                <Form.Item>
                                    {getFieldDecorator("template", {
                                        rules: [
                                            { required: true, message: "El campo Plantilla es requerido" }
                                        ]
                                    })(
                                        <Select
                                            loading={loading}
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Seleccione una plantilla"
                                        >
                                            {templates.map(template => (
                                                <Select.Option key={template.id} value={template.id}>
                                                    {template.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                    <Col md={8}>
                                <Form.Item required label="Asunto" style={{ marginBottom: 0 }} />
                                <Form.Item>
                                    {getFieldDecorator("subject", {
                                        rules: [
                                            { required: true, message: "El campo Asunto es requerido" }
                                        ]
                                    })(
                                        <Input
                                            prefix={
                                                <Icon type="message" style={{ color: "rgba(0,0,0,.25)" }} />
                                            }
                                            placeholder="Asunto"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                </Row>

                <Row gutter={16}>
                    <Col md={16}>
                        <Form.Item required label="Mensaje" style={{marginBottom: 0}}/>
                        <Form.Item>
                            {getFieldDecorator("message", {
                                rules: [
                                    {required: true, message: "El campo Mensaje es requerido"}
                                ],
                                initialValue: ''
                            })(
                                <ReactQuill theme="snow" modules={editorModules} formats={editorFormats}
                                            className="custom-ql-editor"/>
                            )}
                        </Form.Item>
                    </Col>

                    <Col md={8}>
                        <Form.Item required label="Destinatarios" style={{marginBottom: 0}}/>
                        <Form.Item>
                            {getFieldDecorator("contactType", {
                                initialValue: selectedContactType,
                            })(
                                <Select
                                    optionFilterProp="children"
                                    placeholder="Seleccione un tipo"
                                    onChange={handleContactTypeChange}
                                >
                                    {contactType.map(type => (
                                        <Select.Option key={type.id} value={type.id}>
                                            {type.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        { selectedContactType === 'manually' &&
                            <Form.Item>
                            {getFieldDecorator("to", {
                                rules: [
                                    {required: true, message: "El campo Destinatarios es requerido"}
                                ]
                            })(
                                <div>
                                    {emails.map((email, index) => {
                                        const tagElem = (
                                            <Tag key={email} closable
                                                 onClose={() => handleCloseEmailTag(email)}>
                                                {email}
                                            </Tag>
                                        );
                                        return (tagElem);
                                    })}
                                    {inputVisible && (
                                        <Input
                                            type="text"
                                            size="small"
                                            style={{width: 100}}
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            onBlur={handleInputConfirm}
                                            onPressEnter={handleInputConfirm}
                                        />
                                    )}
                                    {!inputVisible && (
                                        <Tag onClick={showInput} style={{background: '#fff', borderStyle: 'dashed'}}>
                                            <Icon type="plus"/>
                                            Nuevo Email
                                        </Tag>
                                    )}
                                </div>
                            )}
                        </Form.Item>
                        }
                        {selectedContactType === 'csv' &&
                            <Form.Item extra="">
                            {getFieldDecorator("fileList", {
                                rules: [
                                    { required: true, message: "El campo Destinatarios es requerido" }
                                ]
                            })(<Upload
                                    beforeUpload={beforeUpload}
                                    onRemove={onRemove}
                                    fileList={fileList}
                                    accept={"text/*"}
                                >
                                    <Button>
                                        <Icon type="upload"/>
                                        Clic para subir csv
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>
                        }
                    </Col>
                </Row>

                <Form.Item className="text-center" style={{ marginTop: 5 }}>
                    <Button
                        type="primary"
                        icon="mail"
                        htmlType="submit"
                        size="large"
                        loading={submitting}
                    >
                        Enviar Emails
                    </Button>
                </Form.Item>
                </Spin>
            </Form>
        </>
    );
};

export default Form.create({ name: "send_email_form" })(SendEmailForm);
