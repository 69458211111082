import React from "react";
import SendEmailForm from "../sendmail/SendEmailForm";

const Page = ({ }) => {
    return (
        <>
            <SendEmailForm/>
        </>
    );
};

export default Page;