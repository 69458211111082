import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    PageHeader,
    Tabs,
    Slider,
    message as Message, InputNumber, message
} from "antd";

import * as metricService from  "../../api/services/metric.service";

const MetricEditForm = props => {

    const [submitting, setSubmit] = useState(false)
    const [metric, setMetric] = useState({});
    const[ahrefsReferringDomainMinValue, setAhrefsReferringDomainMinValue] = useState(0);
    const[ahrefsReferringDomainMaxValue, setAhrefsReferringDomainMaxValue] = useState(0);
    const[ahrefsGovExternalLinksMinValue, setAhrefsGovExternalLinksMinValue] = useState(0);
    const[ahrefsGovExternalLinksMaxValue, setAhrefsGovExternalLinksMaxValue] = useState(0);
    const[ahrefsEduExternalLinksMinValue, setAhrefsEduExternalLinksMinValue] = useState(0);
    const[ahrefsEduExternalLinksMaxValue, setAhrefsEduExternalLinksMaxValue] = useState(0);
    const[ahrefsRefClassCMinValue, setAhrefsRefClassCMinValue] = useState(0);
    const[ahrefsRefClassCMaxValue, setAhrefsRefClassCMaxValue] = useState(0);
    const[ahrefsBacklinksMinValue, setAhrefsBacklinksMinValue] = useState(0);
    const[ahrefsBacklinksMaxValue, setAhrefsBacklinksMaxValue] = useState(0);
    const[ahrefsTrafficMinValue, setAhrefsTrafficMinValue] = useState(0);
    const[ahrefsTrafficMaxValue, setAhrefsTrafficMaxValue] = useState(0);
    const[semrushKeywordsMinValue, setSemrushKeywordsMinValue] = useState(0);
    const[semrushKeywordsMaxValue, setSemrushKeywordsMaxValue] = useState(0);
    const[semrushTrafficMinValue, setSemrushTrafficMinValue] = useState(0);
    const[semrushTrafficMaxValue, setSemrushTrafficMaxValue] = useState(0);

    useEffect(() => {
        getMetric();
    }, []);

    const onChangeReferringDomainMin = value => {
        let maxValue = props.form.getFieldValue('ahrefs_refdomains')[1];

        setAhrefsReferringDomainMinValue(value);

        props.form.setFields({
            ahrefs_refdomains: {
                value: [value, maxValue]
            }
        });

    };

    const onChangeReferringDomainMax = value => {
        let minValue = props.form.getFieldValue('ahrefs_refdomains')[0];

        setAhrefsReferringDomainMaxValue(value);

        props.form.setFields({
            ahrefs_refdomains: {
                value: [minValue, value]
            }
        });
    };

    const onChangeGovExternalLinksMin = value => {
        let maxValue = props.form.getFieldValue('ahrefs_gov')[1];

        setAhrefsGovExternalLinksMinValue(value);
        props.form.setFields({
            ahrefs_gov: {
                value: [value, maxValue]
            }
        });
    };

    const onChangeGovExternalLinksMax = value => {
        let minValue = props.form.getFieldValue('ahrefs_gov')[0];

        setAhrefsGovExternalLinksMaxValue(value);

        props.form.setFields({
            ahrefs_gov: {
                value: [minValue, value]
            }
        });
    };

    const onChangeEduExternalLinksMin = value => {
        setAhrefsEduExternalLinksMinValue(value);

        let maxValue = props.form.getFieldValue('ahrefs_edu')[1];

        props.form.setFields({
            ahrefs_edu: {
                value: [value, maxValue]
            }
        });
    };

    const onChangeEduExternalLinksMax = value => {
        setAhrefsEduExternalLinksMaxValue(value);

        let minValue = props.form.getFieldValue('ahrefs_edu')[0];

        props.form.setFields({
            ahrefs_edu: {
                value: [minValue, value]
            }
        });
    };

    const onChangeRefClassCMin = value => {
        setAhrefsRefClassCMinValue(value);

        props.form.setFields({
            ahrefs_refclass_c: {
                value: [value, props.form.getFieldValue('ahrefs_refclass_c')[1]]
            }
        });
    };

    const onChangeRefClassCMax = value => {
        setAhrefsRefClassCMaxValue(value);

        let minValue = props.form.getFieldValue('ahrefs_refclass_c')[0];

        props.form.setFields({
            ahrefs_refclass_c: {
                value: [minValue, value]
            }
        });
    };

    const onChangeAhrefsBacklinksMin = value => {
        setAhrefsBacklinksMinValue(value);

        props.form.setFields({
            ahrefs_backlinks: {
                value: [value, props.form.getFieldValue('ahrefs_backlinks')[1]]
            }
        });
    };
    const onChangeAhrefsBacklinksMax = value => {
        setAhrefsBacklinksMaxValue(value);

        let minValue = props.form.getFieldValue('ahrefs_backlinks')[0];

        props.form.setFields({
            ahrefs_backlinks: {
                value: [minValue, value]
            }
        });
    };

    const onChangeAhrefsTrafficMin = value => {
        setAhrefsTrafficMinValue(value);
        props.form.setFields({
            ahrefs_traffic: {
                value: [value, props.form.getFieldValue('ahrefs_traffic')[1]]
            }
        });
    };
    const onChangeAhrefsTrafficMax = value => {
        setAhrefsTrafficMaxValue(value);

        let minValue = props.form.getFieldValue('ahrefs_traffic')[0];

        props.form.setFields({
            ahrefs_traffic: {
                value: [minValue, value]
            }
        });
    };

    const onChangeSemrushKeywordsMin = value => {
        setSemrushKeywordsMinValue(value);
        props.form.setFields({
            sem_or: {
                value: [value, props.form.getFieldValue('sem_or')[1]]
            }
        });
    };

    const onChangeSemrushKeywordsMax = value => {
        setSemrushKeywordsMaxValue(value);

        let minValue = props.form.getFieldValue('sem_or')[0];

        props.form.setFields({
            sem_or: {
                value: [minValue, value]
            }
        });
    };

    const onChangeSemrushTrafficMin = value => {
        setSemrushTrafficMinValue(value);
        props.form.setFields({
            sem_ot: {
                value: [value, props.form.getFieldValue('sem_ot')[1]]
            }
        });
    };

    const onChangeSemrushTrafficMax = value => {
        setSemrushTrafficMaxValue(value);

        let minValue = props.form.getFieldValue('sem_ot')[0];

        props.form.setFields({
            sem_ot: {
                value: [minValue, value]
            }
        });
    };

    const getMetric = async () => {
        try {
            const metricData = await metricService.get(props.id);
            if (metricData.data.items && metricData.data.items.length > 0) {
                const item = metricData.data.items[0];

                props.form.setFields({
                    name: {
                        value: item.name
                    },
                    moz_pda: {
                        value: [item.moz.moz_pda_min, item.moz.moz_pda_max]
                    },
                    moz_upa: {
                        value: [item.moz.moz_upa_min, item.moz.moz_upa_max]
                    },
                    moz_umrp: {
                        value: [item.moz.moz_umrp_min, item.moz.moz_umrp_max]
                    },
                    moz_fmrp: {
                        value: [item.moz.moz_fmrp_min, item.moz.moz_fmrp_max]
                    },
                    moz_fspsc: {
                        value: [item.moz.moz_fspsc_min, item.moz.moz_fspsc_max]
                    },
                    majestic_citation_flow: {
                        value: [item.majestic.majestic_citation_flow_min, item.majestic.majestic_citation_flow_max]
                    },
                    majestic_trust_flow: {
                        value: [item.majestic.majestic_trust_flow_min, item.majestic.majestic_trust_flow_max]
                    },
                    ahrefs_domain_rating: {
                        value: [item.ahrefs.ahrefs_domain_rating_min, item.ahrefs.ahrefs_domain_rating_max]
                    },
                    ahrefs_backlinks: {
                        value: [item.ahrefs.ahrefs_backlinks_min, item.ahrefs.ahrefs_backlinks_max]
                    },
                    ahrefs_refdomains: {
                        value: [item.ahrefs.ahrefs_refdomains_min, item.ahrefs.ahrefs_refdomains_max]
                    },
                    ahrefs_gov: {
                        value: [item.ahrefs.ahrefs_gov_min, item.ahrefs.ahrefs_gov_max]
                    },
                    ahrefs_edu: {
                        value: [item.ahrefs.ahrefs_edu_min, item.ahrefs.ahrefs_edu_max]
                    },
                    ahrefs_refclass_c: {
                        value: [item.ahrefs.ahrefs_refclass_c_min, item.ahrefs.ahrefs_refclass_c_max]
                    },
                    ahrefs_traffic: {
                        value: [item.ahrefs.ahrefs_traffic_min, item.ahrefs.ahrefs_traffic_max]
                    },
                    sem_or: {
                        value: [item.semrush.sem_or_min, item.semrush.sem_or_max]
                    },
                    sem_ot: {
                        value: [item.semrush.sem_ot_min, item.semrush.sem_ot_max]
                    },
                    google_pr: {
                        value: [item.google.google_pr_min, item.google.google_pr_max]
                    }
                });

                setAhrefsReferringDomainMinValue(item.ahrefs.ahrefs_refdomains_min);
                setAhrefsReferringDomainMaxValue(item.ahrefs.ahrefs_refdomains_max);
                setAhrefsGovExternalLinksMinValue(item.ahrefs.ahrefs_gov_min);
                setAhrefsGovExternalLinksMaxValue(item.ahrefs.ahrefs_gov_max);
                setAhrefsEduExternalLinksMinValue(item.ahrefs.ahrefs_edu_min);
                setAhrefsEduExternalLinksMaxValue(item.ahrefs.ahrefs_edu_max);
                setAhrefsRefClassCMinValue(item.ahrefs.ahrefs_refclass_c_min);
                setAhrefsRefClassCMaxValue(item.ahrefs.ahrefs_refclass_c_max);
                setAhrefsBacklinksMinValue(item.ahrefs.ahrefs_backlinks_min);
                setAhrefsBacklinksMaxValue(item.ahrefs.ahrefs_backlinks_max);
                setAhrefsTrafficMinValue(item.ahrefs.ahrefs_traffic_min);
                setAhrefsTrafficMaxValue(item.ahrefs.ahrefs_traffic_max);
                setSemrushKeywordsMinValue(item.semrush.sem_or_min);
                setSemrushKeywordsMaxValue(item.semrush.sem_or_max);
                setSemrushTrafficMinValue(item.semrush.sem_ot_min);
                setSemrushTrafficMaxValue(item.semrush.sem_ot_max);

                setMetric(item);
            }

        } catch (err) {
        }
    };
    const { TabPane } = Tabs;

    const marks = {
        0: '0',
        25: '25',
        50: '50',
        75: '75',
        100: {
            style: {
                color: '#f50',
            },
            label: <strong>100</strong>,
        },
    };

    const marks0_10 = {
        0: '0',
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: {
            style: {
                color: '#f50',
            },
            label: <strong>10</strong>,
        },
    };

    const marks0_17 = {
        0: '0',
        4: '4',
        9: '9',
        13: '13',
        17: {
            style: {
                color: '#f50',
            },
            label: <strong>17</strong>,
        },
    };

    const validateMetrics = () => {
        let messages=[];

        if (ahrefsReferringDomainMinValue > ahrefsReferringDomainMaxValue) {
            messages.push('El valor mínimo de Referring Domains debe ser mayor que el valor máximo');
        }
        if(ahrefsGovExternalLinksMinValue > ahrefsGovExternalLinksMaxValue) {
            messages.push('El valor mínimo de Gov External Links debe ser mayor que el valor máximo');
        }
        if(ahrefsEduExternalLinksMinValue > ahrefsEduExternalLinksMaxValue) {
            messages.push('El valor mínimo de Edu External Links debe ser mayor que el valor máximo');
        }
        if(ahrefsRefClassCMinValue > ahrefsRefClassCMaxValue) {
            messages.push('El valor mínimo de Ref Class C debe ser mayor que el valor máximo');
        }
        if(ahrefsBacklinksMinValue > ahrefsBacklinksMaxValue) {
            messages.push('El valor mínimo de Backlinks debe ser mayor que el valor máximo');
        }
        if(ahrefsTrafficMinValue > ahrefsTrafficMaxValue) {
            messages.push('El valor mínimo de Traffic debe ser mayor que el valor máximo');
        }
        if(semrushKeywordsMinValue > semrushKeywordsMaxValue) {
            messages.push('El valor mínimo de SEMrush Keywords debe ser mayor que el valor máximo');
        }
        if(semrushTrafficMinValue > semrushTrafficMaxValue) {
            messages.push('El valor mínimo de SEMrush Traffic debe ser mayor que el valor máximo');
        }

        if(messages.length == 0){
            return true;
        } else {
            let idx = 1;
            let warningMsg="Algunas métricas no están configuradas corectamente:";
            messages.map(msg => {
                warningMsg = `${warningMsg} ${idx}- ${msg}.`;
                idx++;
            });

            message.warning(warningMsg);

            return false;
        }

    }

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                if (validateMetrics()) {
                    setSubmit(true);
                    await metricService.edit(metric.id, values);
                    props.history.push("/metrics/list");
                }
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Editar Métrica"
                backIcon={null}
            />
            <Form onSubmit={handleSubmit} autoComplete="off">
                <Row gutter={16}>
                    <Col md={6}>
                        <Form.Item required label="Nombre" style={{ marginBottom: 0 }} />
                        <Form.Item>
                            {getFieldDecorator("name", {
                                rules: [
                                    { required: true, message: "El campo Nombre es requerido" }
                                ]
                            })(
                                <Input

                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                        <Col md={24}>
                            <Form.Item label="Métricas" style={{ marginBottom: 0 }} />
                            <Form.Item>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="MOZ" key="moz" style={{padding: '10px 20px'}}>
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Form.Item label="Moz Domain Authority" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_pda", {

                                                })(
                                                    <Slider range marks={marks} min={0} max={100} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Moz Homepage Authority" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_upa", {
                                                })(
                                                    <Slider range min={0} max={100} marks={marks} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Moz Homepage MozRank" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_umrp", {

                                                })(
                                                    <Slider range min={0} max={10} marks={marks0_10} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Moz Subdomain MozRank" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_fmrp", {
                                                })(
                                                    <Slider range min={0} max={10} marks={marks0_10} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Moz Spam Score" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("moz_fspsc", {
                                                })(
                                                    <Slider range min={0} max={17} marks={marks0_17} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="Majestic" key="majestic">
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Form.Item label="Majestic Citation Flow" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("majestic_citation_flow", {
                                                })(
                                                    <Slider range marks={marks} min={0} max={100} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Item label="Majestic Trust Flow" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("majestic_trust_flow", {
                                                })(
                                                    <Slider range min={0} max={100} marks={marks} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="ahrefs" key="ahrefs">
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Form.Item label="Domain Rating" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("ahrefs_domain_rating", {
                                                })(
                                                    <Slider range marks={marks} min={0} max={100}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={32}>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Referring Domains" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsReferringDomainMinValue}
                                                        onChange={onChangeReferringDomainMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsReferringDomainMaxValue}
                                                        onChange={onChangeReferringDomainMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Gov External Links" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsGovExternalLinksMinValue}
                                                        onChange={onChangeGovExternalLinksMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsGovExternalLinksMaxValue}
                                                        onChange={onChangeGovExternalLinksMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Edu External Links" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsEduExternalLinksMinValue}
                                                        onChange={onChangeEduExternalLinksMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsEduExternalLinksMaxValue}
                                                        onChange={onChangeEduExternalLinksMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Ref Class C" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsRefClassCMinValue}
                                                        onChange={onChangeRefClassCMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsRefClassCMaxValue}
                                                        onChange={onChangeRefClassCMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Backlinks" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsBacklinksMinValue}
                                                        onChange={onChangeAhrefsBacklinksMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsBacklinksMaxValue}
                                                        onChange={onChangeAhrefsBacklinksMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8} style={{ marginTop: 20 }}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="Traffic" style={{ marginBottom: 0 }} />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsTrafficMinValue}
                                                        onChange={onChangeAhrefsTrafficMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{ marginBottom: 0 }} />
                                                    <InputNumber
                                                        min={-1}
                                                        value={ahrefsTrafficMaxValue}
                                                        onChange={onChangeAhrefsTrafficMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="SEMrush" key="semrush">
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="SEMrush Keywords" style={{marginBottom: 0}}/>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{marginBottom: 0}}/>
                                                    <InputNumber
                                                        min={-1}
                                                        value={semrushKeywordsMinValue}
                                                        onChange={onChangeSemrushKeywordsMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{marginBottom: 0}}/>
                                                    <InputNumber
                                                        min={-1}
                                                        value={semrushKeywordsMaxValue}
                                                        onChange={onChangeSemrushKeywordsMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8}>
                                            <Row gutter={32}>
                                                <Col md={24}>
                                                    <Form.Item label="SEMrush Traffic" style={{marginBottom: 0}}/>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Min" style={{marginBottom: 0}}/>
                                                    <InputNumber
                                                        min={-1}
                                                        value={semrushTrafficMinValue}
                                                        onChange={onChangeSemrushTrafficMin}
                                                    />
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item label="Max" style={{marginBottom: 0}}/>
                                                    <InputNumber
                                                        min={-1}
                                                        value={semrushTrafficMaxValue}
                                                        onChange={onChangeSemrushTrafficMax}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="Google" key="google">
                                    <Row gutter={32}>
                                        <Col md={8}>
                                            <Form.Item label="PageRank" style={{ marginBottom: 0 }} />
                                            <Form.Item>
                                                {getFieldDecorator("google_pr", {
                                                })(
                                                    <Slider range marks={marks0_10} min={0} max={10}/>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                            </Form.Item>
                        </Col>
                    </Row>
                <Form.Item className="text-center">
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={submitting}
                    >
                        Guardar
                    </Button>
                </Form.Item>
                {getFieldDecorator("ahrefs_refdomains", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_gov", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_edu", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_refclass_c", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_backlinks", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("ahrefs_traffic", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("sem_or", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
                {getFieldDecorator("sem_ot", {
                    initialValue: [-1, -1]
                })(<input type="hidden" />)}
            </Form>
        </>
    );
}

export default withRouter(
    Form.create({ name: "edit-metric-form" })(MetricEditForm)
);