import React from "react";
import { Switch, Route } from "react-router-dom";

import Page404 from "./pages/404";
import PageIndex from "./pages/inicio";
import PageLogin from "./pages/login";
import AuthRoutes from "./AuthRoutes";
import ContactConfirmed from "./pages/ContactConfirmed"

const Routes = props => {
  return (
    <Switch>
      <Route path="/" exact>
        <PageIndex />
      </Route>

        <Route path="/confirmed" exact>
           <ContactConfirmed />
        </Route>

      <Route
        path="/login"
        exact
        render={renderProps => <PageLogin {...renderProps} />}
      />

      <AuthRoutes />

      <Route render={routeProps => <Page404 {...routeProps} />} />
    </Switch>
  );
};

export default Routes;
