import React, { useEffect, useState } from "react";
import {
    Form, Icon, Input, Button, Row, Col, message, Select, PageHeader
} from "antd";
import {withRouter} from "react-router-dom";
import * as twitterService from  "../../../api/services/twitter.service";

const CreateTweetForm = props => {
    const { getFieldDecorator } = props.form;
    const [messageType, setMessageType] = useState([]);
    const [selectedMessageType, setSelectedMessageType] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const { TextArea } = Input;

    useEffect(() => {
        (async () => {
            try {
                setMessageTypeValues();
            } catch (err) {}
        })();
    }, []);

    const setMessageTypeValues = () => {
        const data = [{
            id: "direct-message",
            name: "Mensaje Directo"
        }, {
            id: "tweet",
            name: "Tweet"
        }];

        setMessageType(data)
    };

    const handleMessageTypeChange = e => {
        setSelectedMessageType(e);
    };

    const handleSubmit = e => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            try {
                if (!err) {
                    setSubmitting(true);
                    if (selectedMessageType === 'direct-message') {
                        const result = await twitterService.sendMessageByUsername(values);
                        setSubmitting(false);
                        console.log(result);
                        if (result.data && result.data.data.event) {
                            message.success("El mensaje se ha enviado satisfactoriamente");
                        } else {
                            message.info("El mensaje no se pudo enviar");
                        }
                    } else {
                        const result = await twitterService.sendTweet(values);
                        setSubmitting(false);
                        console.log(result);
                        if (result.data && result.data.data.id) {
                            message.success("El tweet se ha enviado satisfactoriamente");
                        } else {
                            message.info("El tweet no se pudo enviar");
                        }
                    }
                } else {
                    message.warning("Por favor, verifique que todos los campos estén correctos");
                }
            } catch (e) {
                console.log(e);
                setSubmitting(false);
                message.warning("Ha ocurrido un error al enviar el mensaje");
            }
        });
    };

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Enviar mensaje"
                backIcon={null}
            />
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row gutter={16}>
                        <Col md={8}>
                            <Form.Item required label="Tipo de Mensaje" style={{ marginBottom: 0 }} />
                            <Form.Item>
                                {getFieldDecorator("filterType", {
                                    rules: [
                                        { required: true, message: "El campo Tipo de Mensaje es requerido" }
                                    ]
                                })(
                                    <Select
                                        optionFilterProp="children"
                                        placeholder="Seleccione un tipo"
                                        onChange={handleMessageTypeChange}
                                    >
                                        {messageType.map(type => (
                                            <Select.Option key={type.id} value={type.id}>
                                                {type.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        {selectedMessageType === 'direct-message' &&
                        <Col md={8}>
                            <Form.Item required label="Usuario" style={{marginBottom: 0}}/>
                            <Form.Item>
                                {getFieldDecorator("username", {
                                    rules: [
                                        {required: true, message: "El campo Usuario es requerido"}
                                    ]
                                })(
                                    <Input/>
                                )}
                            </Form.Item>
                        </Col>
                        }

                    </Row>
                    <Row gutter={16}>
                        <Col md={16}>
                            <Form.Item required label="Mensaje" style={{marginBottom: 0}}/>
                            <Form.Item>
                                {getFieldDecorator("message", {
                                    rules: [
                                        {required: true, message: "El campo Mensaje es requerido"}
                                    ]
                                })(
                                    <TextArea rows={5} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                        <Button type="primary" htmlType="submit"
                                loading={submitting}>
                            Enviar
                        </Button>
                    </Form.Item>
                </Form>
        </>
    );
};

export default withRouter (
    Form.create({ name: "create-tweet-form" })(CreateTweetForm)
);
