import React from "react";
import { withRouter } from "react-router-dom";
import MetricEditForm from "../../components/forms/MetricEditForm";

const Page = ({ match }) => {
    return (
        <>
            <MetricEditForm id={match.params.id}/>
        </>
    );
};

export default withRouter(Page);