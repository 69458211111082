import React, { useEffect, useState } from "react";
import {
    Form,
    Icon,
    Input,
    Button,
    Row,
    Col,
    message,
    Select, PageHeader,
} from "antd";

const SearchFollowersForm = props => {
    const { getFieldDecorator } = props.form;
    const [searchType, setSearchType] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                setSearchTypeValues();
            } catch (err) {}
        })();
    }, []);

    const setSearchTypeValues = () => {
        const data = [{
            id: "followers",
            name: "Followers"
        }, {
            id: "following",
            name: "Following"
        }];

        setSearchType(data)
    };

    const handleSubmit = e => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            if (!err) {
                if (props.onSearch) {
                    props.onSearch(values);
                }
            } else {
                message.warning("Por favor, verifique que todos los campos estén correctos");
            }
        });
    };

    const handleReset = e => {
        e.preventDefault();

        props.form.setFields({
            username: {
                value: ''
            },
            filterType: {
                value: ''
            }
        });
    }

    return (
        <>
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row gutter={16}>
                        <Col md={8}>
                            <Form.Item required label="Usuario" style={{marginBottom: 0}}/>
                            <Form.Item>
                                {getFieldDecorator("username", {
                                    rules: [
                                        { required: true, message: "El campo Usuario es requerido" }
                                    ]
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item required label="Tipo" style={{ marginBottom: 0 }} />
                            <Form.Item>
                                {getFieldDecorator("filterType", {
                                    rules: [
                                        { required: true, message: "El campo Tipo de Búsqueda es requerido" }
                                    ]
                                })(
                                    <Select
                                        optionFilterProp="children"
                                        placeholder="Seleccione un tipo"
                                    >
                                        {searchType.map(type => (
                                            <Select.Option key={type.id} value={type.id}>
                                                {type.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                        <Button type="primary" htmlType="submit">
                            Buscar
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button onClick={handleReset}>Cancelar</Button>
                    </Form.Item>
                </Form>
        </>
    );
};

export default (
    Form.create({ name: "search-follower-form" })(SearchFollowersForm)
);
