import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Icon, Input, Button, Select, Row, Col, PageHeader } from "antd";
import Cliente from "../../api/Cliente";

const EmailForm = props => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const { getFieldDecorator } = props.form;

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        try {
          await Cliente.getCliente().post(
            `${process.env.REACT_APP_API_URL}outreacher-email`,
            {
              ...values
            }
          );

          props.history.push("/emails/listar");
        } catch (err) {
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <PageHeader onBack={() => null} title="Agregar email" backIcon={null} />

      <Form onSubmit={handleSubmit} className="login-form" autoComplete="off">
        <Row gutter={16}>
          <Col md={8}>
            <Form.Item required label="Correo" style={{ marginBottom: 0 }} />
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "El campo no es correo valido"
                  },
                  { required: true, message: "El campo Correo es requerido" }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Correo"
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="text-center">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
          >
            Agregar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default withRouter(Form.create({ name: "usuario_form" })(EmailForm));
