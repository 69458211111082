import React, {useState} from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

let defaultTheme = "light";
if (localStorage.getItem("theme") === null) {
    localStorage.setItem("theme", "light");
} else {
    defaultTheme = localStorage.getItem("theme");
}

ReactDOM.render(
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={defaultTheme}>
        <App />
    </ThemeSwitcherProvider>, document.getElementById("root"));
serviceWorker.unregister();
