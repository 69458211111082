import Cliente from "../Cliente";

export const getCampaigns = (params = {}) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}campaigns/`,{params: params});
};

export const getDiscoveryTypes = (params = {}) => {
    return Cliente.setBearerToken()
        .getCliente()
        .get(`${process.env.REACT_APP_API_URL}campaigns/discovery-types`,{params: params});
};

export const createImportCampaign = (formData) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}campaigns/import`, formData);
};

export const createDiscoveryCampaign = (values) => {
    return Cliente.setBearerToken()
        .getCliente()
        .post(`${process.env.REACT_APP_API_URL}campaigns/discovery`, {
            values
        });
};