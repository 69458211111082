import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {Table, Divider, PageHeader, Popconfirm, Col, Button, Row, Input, Icon, Tag, Card} from "antd";
import CreateCampaignForm from "./create-campaign-form";
import * as campaignService from  "../../api/services/campaign.service";
const Page = () => {
    const [campaignTable, setCampaignTable] = useState({
        data: [],
        pagination: {
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            locale: { items_per_page: "registros" }
        },
        loading: false,
    });

    let history = useHistory();

    const [searchInput, setSearchInput] = useState(null);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        setSearchInput(node);
                    }}
                    placeholder={'Entre un valor'}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        }
    });

    const columns = [
        {
            title: "NOMBRE",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps('name'),
        },
        {
            title: "PROYECTO",
            dataIndex: "project",
            key: "project"
        },
        {
            title: "TIPO",
            dataIndex: "type",
            key: "type"
        },
        {
            title: "PAIS",
            dataIndex: "country",
            key: "country"
        },
        {
            title: "ESTADO",
            dataIndex: "status",
            key: "status",
            render: status => (
               <span>
                   {
                       <Tag key={status} className={status === "active" ? "custom-tag green-tag" : "custom-tag red-tag"}>
                           {status === "active" ? 'Activo' : 'Archivada'}
                       </Tag>

                   }
                </span>
            ),
        }
    ];

    useEffect(() => {
        getCampaigns();
    }, []);


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

   const handleReset = clearFilters => {
        clearFilters();
    };

    const handleCampaignTableChange = (pagination, filters, sorter) => {
        const pager = { ...campaignTable.pagination };
        pager.current = pagination.current;
        setCampaignTable({
            pagination: pager,
        });

        getCampaigns({
            limit: pagination.pageSize,
            page: pagination.current,
            ...filters
        });
    };

    const getCampaigns = async (params = {}) => {
        try {
            setCampaignTable({
                loading: true
            });

            if(!params.limit){
                params.limit = 10;
            }
            if(!params.page){
                params.page = 1;
            }

            const result = await campaignService.getCampaigns(params);
            let items = [];
            result.data.items.map(item => {
                items.push({
                    key: item.id,
                    name: item.name,
                    project: item.project.name,
                    country: item.country.name,
                    type: item.type,
                    status: item.status
                });
            });

            const pagination = { ...campaignTable.pagination };
            pagination.total = result.data.items_count;
            setCampaignTable({
                loading: false,
                data: items,
                pagination,
            });
        } catch (e) {
        }
    };


    const handleGenerateEmail = (record) => {
        history.push(`/campaigns/${record.key}/emails`)
    }
    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Listado de Campañas"
                backIcon={null}
            />
            <Card>
                <Row gutter={16}>
                <Col md={24}>
                    <CreateCampaignForm />
                </Col>
                <Col md={24}>
                    <Table columns={columns} className={"ant-table1-v1 table-striped-rows"}
                           dataSource={campaignTable.data}
                           pagination={campaignTable.pagination}
                           loading={campaignTable.loading}
                           onChange={handleCampaignTableChange}
                           onRow={(record, rowIndex) => {
                               return {
                                   onDoubleClick: () => {
                                       handleGenerateEmail(record);
                                   },
                               }
                           }}
                    />
                </Col>
            </Row>
            </Card>
        </>
    );
};

export default Page;
