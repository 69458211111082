import Cliente from "../Cliente";

//getFollowersByUsername
export const getFollowersByUsername = (username, params = {}) => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(
      `${process.env.REACT_APP_API_URL}client/twitter/users/by/username/${username}/followers`,
      { params: params }
    );
};

export const getFollowingByUsername = (username, params = {}) => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(
      `${process.env.REACT_APP_API_URL}client/twitter/users/by/username/${username}/following`,
      { params: params }
    );
};

export const sendMessageByUsername = (values) => {
  return Cliente.setBearerToken()
    .getCliente()
    .post(`${process.env.REACT_APP_API_URL}client/twitter/messages`, {
      values,
    });
};

export const sendTweet = (values) => {
  return Cliente.setBearerToken()
    .getCliente()
    .post(`${process.env.REACT_APP_API_URL}client/twitter/tweets`, {
      values,
    });
};
