import React from "react";
import { Result, Button } from "antd";

const Page = ({ history }) => {
  const goToHome = () => {
    history.push("/");
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="Lo sentimos, la página que ha visitado no existe."
      extra={
        <Button type="primary" onClick={goToHome}>
          Inicio
        </Button>
      }
    />
  );
};

export default Page;
