import React from "react";
import { withRouter } from "react-router-dom";
import EmailFormEdit from "../../components/forms/EmailFormEdit";

const Page = ({ match }) => {
  return (
    <>
      <EmailFormEdit id={match.params.id} />
    </>
  );
};

export default withRouter(Page);
