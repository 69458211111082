import Cliente from "../Cliente";

export const getWeb = id => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}site/${id}`);
};

export const getWebs = () => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}site/`);
};

export const getWebsOut = () => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}site-outreach/`);
};

export const getWebOutReach = id => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}site-outreach/${id}`);
};

export const destroy = id => {
  return Cliente.setBearerToken()
    .getCliente()
    .delete(`${process.env.REACT_APP_API_URL}site/${id}`);
};

export const destroyOutReach = id => {
  return Cliente.setBearerToken()
    .getCliente()
    .delete(`${process.env.REACT_APP_API_URL}site-outreach/${id}`);
};
