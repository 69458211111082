import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Row, Col, PageHeader, Table, Icon} from "antd";
import * as mailingService from  "../../api/services/mailing.service";
import moment from "moment";

const Page = () => {
    const [inboxTable, setInboxTable] = useState({
        data: [],
        pagination: {
            pageSize: 20
        },
        loading: false,
    });

    useEffect(() => {
        getInbox();
    }, []);

    const getInbox = async (params = {}) => {
        try {
            setInboxTable({
                loading: true
            });
            if(!params.limit){
                params.limit = 20;
            }
            if(!params.page){
                params.page = 1;
            }

            const result = await mailingService.getInboundEmails(params);

            let items = [];
            result.data.items.map(item => {
                items.push({
                    key: item.id,
                    from:`${item.from_name} <${item.from_email}>`,
                    subject: item.subject,
                    date: moment(item.created_at, "YYYY-MM-DDTHH:mm:ssZZ").format("YYYY-MM-DD HH:mm"),
                    project: item.project.name,
                    opportunity: (item.opportunity) ? (item.opportunity.url).replace("https://", "").replace("http://", "") : "",
                });
            });

            const pagination = { ...inboxTable.pagination };
            pagination.total = result.data.items_count;

            setInboxTable({
                loading: false,
                data: items,
                pagination,
            });
        } catch (e) {
            console.log(e)
        }
    };

    const handleInboxTableChange = async (pagination, filters, sorter) => {
        const pager = { ...inboxTable.pagination };
        pager.current = pagination.current;
        setInboxTable({
            pagination: pager,
        });

       await getInbox({
            limit: pagination.pageSize,
            page: pagination.current
        });
    };

    const columns = [
        {
            title: "De",
            dataIndex: "from",
            key: "from",
            width: 250,
        },
        {
            title: "Asunto",
            dataIndex: "subject",
            key: "subject",
            width: 250,
        },
        {
            title: "Fecha",
            dataIndex: "date",
            key: "date",
            width: 250,
        },
        {
            title: "Proyecto",
            dataIndex: "project",
            key: "project"
        },
        {
            title: "Dominio",
            dataIndex: "opportunity",
            key: "opportunity"
        },
        {
            title: "Acción",
            key: "action",
            render: (text, record) => (
                <span>
                    <Link to={`/pitchbox/emails/inbox/${record.key}`}>
                        <Icon type="eye" style={{ paddingRight: 5 }}/>
                        Ver
                    </Link>
                </span>
            )
        }
    ];

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Bandeja de entrada"
                backIcon={null}
            />
            <Row gutter={16}>
                <Col md={24}>
                    <Table columns={columns}
                           dataSource={inboxTable.data}
                           pagination={inboxTable.pagination}
                           loading={inboxTable.loading}
                           onChange={handleInboxTableChange}
                           />
                </Col>
            </Row>
        </>
    );
};

export default Page;
