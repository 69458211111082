import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "antd";
import SideBar from "./components/SideBar";
import "./App.scss";
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";
import Routes from "./routes";

const { Content } = Layout;

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      collapsed: false
    };
  }
  toggleSideBar = (data) => {
    this.setState({collapsed: data});
  }

  render() {
  const { collapsed } = this.state;
    return (
      <>
        <Router>
          <Layout style={{ minHeight: "100vh" }}>
            <SideBar collapsed={collapsed}/>
            <Layout>
              <AppHeader toggleSideBar={this.toggleSideBar} />
              <Content className="separator-2">
                <div className="separator" />

                <div className="container">
                  <Routes />
                </div>
              </Content>
              <AppFooter />
            </Layout>
          </Layout>
        </Router>
      </>
    );
  }
}
export default App;
