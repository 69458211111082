import React from "react";
import {Result, Row, Col} from "antd";

const ContactConfirmed = () => {
        return (
        <>
            <Row type="flex" justify="center">
                <Col span={6}>
                    <Result
                        status="success"
                        title="Your confirmation has been sent."
                        subTitle="Let's stay in touch!."
                    />
                </Col>
            </Row>
        </>
        );
};

export default ContactConfirmed;
