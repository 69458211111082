import React from "react";
import { withRouter } from "react-router-dom";
import CreateTemplateForm from "../templates/create.form";

const Page = () => {
    return (
        <>
            <CreateTemplateForm/>
        </>
    );
};

export default withRouter(Page);
