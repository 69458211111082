import Cliente from "../Cliente";

export const list = (params = {}) => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}message/`, {params: params});
};

/**
 * Get message
 * @param {*} message_id 
 */
export const get = message_id => {
  return Cliente.setBearerToken()
    .getCliente()
    .get(`${process.env.REACT_APP_API_URL}message/${message_id}`);
};
