import React, { useEffect, useState } from "react";

import {
    Form,
    Icon,
    Button,
    Row,
    Col,
    Upload,
    message
} from "antd";

const FilesForm = props => {
    const [fileList, setFileList] = useState([]);
    const [clientFileList, setClientFileList] = useState([]);

    useEffect(() => {
        (async () => {
            try {
            } catch (err) {}
        })();
    }, []);

    React.useEffect(() => {
    }, [clientFileList, fileList]);

    const beforeUploadClientFile = (file, fList) => {
        const arr = file.name.split(".");
        const ext = arr[arr.length - 1].toLowerCase();

        if (ext !== "xlsx") {
            message.error("Archivo no disponible. Solo con extensión .xlsx");
            return;
        }

        setClientFileList([file]);

        return false;
    };

    const onRemoveClientFile = (file) => {
        setClientFileList([]);

        return {
            fileList: []
        };
    };

    const beforeUpload = (file, fList) => {
        const arr = file.name.split(".");
        const ext = arr[arr.length - 1].toLowerCase();

        if (ext !== "xlsx") {
            message.error("Archivo no disponible. Solo con extensión .xlsx");
            return false;
        }
        setFileList( f => [...f, file]);

        return false;
    };

    const onRemove = (file) => {
        let files = [... fileList];
        files = files.filter(item => item.name !== file.name)

        setFileList(files);

        return {
            fileList: files
        };
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if(fileList.length > 0 || clientFileList.length > 0) {
            if (props.onProcessLinks) {
                props.onProcessLinks(clientFileList[0], fileList);
            }
        } else {
            message.warning("Entre todos los archivos");
        }
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
            <Form autoComplete="off">
                <Row gutter={16}>
                    <Col md={6}>
                        <Form.Item label="Archivo CSV del cliente" extra="" style={{marginBottom: 4}}>
                            {getFieldDecorator("file", {
                                rules: [
                                    {required: true, message: "Seleccione el archivo"}
                                ]
                            })(<Upload
                                       beforeUpload={beforeUploadClientFile}
                                       onRemove={onRemoveClientFile}
                                       fileList={clientFileList}
                                       accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                                >
                                    <Button>
                                        <Icon type="upload"/>
                                        Clic para subir el archivo
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={6}>
                        <Form.Item label="Archivos CSV de los competidores" extra="" style={{marginBottom: 4}}>
                            {getFieldDecorator("files", {
                                rules: [
                                    {required: true, message: "Seleccione los archivos"}
                                ]
                            })(<Upload multiple={true}
                                       beforeUpload={beforeUpload}
                                       onRemove={onRemove}
                                       fileList={fileList}
                                       accept={"application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                >
                                    <Button>
                                        <Icon type="upload"/>
                                        Clic para subir el archivo
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} style={{marginBottom: 20, marginTop: 20}}>
                    <Col md={12}>
                        <Form.Item className="text-center">
                            <Button
                                type="primary"
                                size="large"
                                onClick={handleSubmit}
                            >
                                Procesar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default Form.create({ name: "backlink_files_form" })(FilesForm);