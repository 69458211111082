import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Table, PageHeader, Col, Row} from "antd";
import * as mailingService from  "../../api/services/mailing.service";
import moment from "moment";

const Page = () => {
    const [mailingTable, setMailingTable] = useState({
        data: [],
        pagination: {
            pageSize: 20
        },
        loading: false,
    });

    useEffect(() => {
        getMailings();
    }, []);

    const getMailings = async (params = {}) => {
        try {
            setMailingTable({
                loading: true
            });
            if(!params.limit){
                params.limit = 20;
            }
            if(!params.page){
                params.page = 1;
            }

            const result = await mailingService.list(params);

            let items = [];
            result.data.items.map(item => {
                items.push({
                    key: item.id,
                    date: moment(item.createdAt, "YYYY-MM-DDTHH:mm:ssZZ").format("YYYY-MM-DD"),
                    country: item.Pais.name
                });
            });

            const pagination = { ...mailingTable.pagination };
            pagination.total = result.data.items_count;

            setMailingTable({
                loading: false,
                data: items,
                pagination,
            });
        } catch (err) {

        }
    };

    const handleMailingTableChange = async (pagination, filters, sorter) => {
        const pager = { ...mailingTable.pagination };
        pager.current = pagination.current;
        setMailingTable({
            pagination: pager,
        });

       await getMailings({
            limit: pagination.pageSize,
            page: pagination.current
        });
    };

    const columns = [
        {
            title: "Fecha",
            dataIndex: "date",
            key: "date"
        },
        {
            title: "Pais",
            dataIndex: "country",
            key: "country"
        },
        {
            title: "Acción",
            key: "action",
            render: (text, record) => (
                <span>
                    <Link to={`/pitchbox/mailing/${record.key}`}>Ver emails</Link>
                </span>
            )
        }
    ];

    return (
        <>
            <PageHeader
                onBack={() => null}
                title="Envíos"
                backIcon={null}
                subTitle="Listado de envíos"
            />
            <Row gutter={16}>
                <Col md={24}>
                    <Table columns={columns} className={"ant-table1-v1 table-striped-rows"}
                           dataSource={mailingTable.data}
                           pagination={mailingTable.pagination}
                           loading={mailingTable.loading}
                           onChange={handleMailingTableChange}
                           />
                </Col>
            </Row>
        </>
    );
};

export default Page;
