import React from "react";
import { Layout } from "antd";
import moment from "moment";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <>
      <Footer style={{ textAlign: "center" }}>
        Tools &copy;{moment().format("YYYY")} Created by Amplificados
      </Footer>
    </>
  );
};

export default AppFooter;
