import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import RouteRol from "./RouteRol";
// import InterconectigForm from "./components/forms/Interconecting";
import InterconectingShowForm from "./components/forms/InterconectingShow";
// import Mensajes from "./pages/mensajes/listar";
import Page404 from "./pages/404";
import token from "./api/token";
import UsuarioForm from "./components/forms/UsuarioForm";
import UsuarioPage from "./pages/usuarios/listar";
import UsuarioEditPage from "./pages/usuarios/edit";
import EmailForm from "./components/forms/EmailForm";
import EmailPage from "./pages/emails/listar";
import EmailEditPage from "./pages/emails/edit";
import ConfiguracionForm from "./components/forms/ConfiguracionForm";
import CampaignCreateForm from  "./components/forms/CampaignCreateForm";
import CampaignList from "./pages/campaign/list";
import CampaignEmailPage from "./pages/campaign/email";
import MetricPage from "./pages/metrics/list";
import MetricCreatePage from "./pages/metrics/create";
import MetricEditPage from "./pages/metrics/edit";
import ContactsPage from "./pages/contacts/list";
import TemplatesPage from "./pages/templates/list";
import TemplatesCreatePage from "./pages/templates/create";
import TemplatesEditPage from "./pages/templates/edit";
import SendEmailForm from "./pages/sendmail/email";
import MailingListPage from "./pages/mailing/list";
import MailingEmailsListPage from "./pages/mailing/email";
import InboxPage from "./pages/mailing/inbox";
import InboundEmailPage from "./pages/mailing/inbound-email";
import TwitterFollowPage from "./pages/twitter/follows/follows";
import SendTweetPage from "./pages/twitter/tweet/send-tweet";
import BacklinkSpySummary from "./pages/backlink/summary/summary";
import InProgressPage from "./pages/in-progress";
import UserPreferencesPage from "./pages/usuarios/preferences";

const AuthRoutes = props => {
  const tok = token();

  useEffect(() => {
    if (!tok) {
      props.history.push("/login");
    }
  }, []);

  if (!tok) return null;

  return (
    <>
      <Switch>

        <Route path="/interconecting/listar" exact>
          <ContactsPage />
        </Route>

        <Route
          path="/interconecting/show/:id"
          render={routeProps => <InterconectingShowForm {...routeProps} />}
        />

        <Route path="/configuracion" exact>
          <ConfiguracionForm />
        </Route>

        <Route path="/campaigns/list" exact>
          <CampaignList />
        </Route>

        <Route path="/campaigns/:id/emails"
        exact
        render={routeProps => <CampaignEmailPage {...routeProps} />}
        />

        <Route path="/metrics/list" exact>
          <MetricPage />
        </Route>

        <Route path="/metrics/create" exact>
          <MetricCreatePage />
        </Route>


        <Route path="/metrics/edit/:id"
            exact
            render={routeProps => <MetricEditPage {...routeProps} />}
        />

        <Route path="/templates/list" exact>
            <TemplatesPage />
        </Route>

        <Route path="/templates/create" exact>
            <TemplatesCreatePage />
        </Route>

        <Route path="/templates/edit/:id" exact>
            <TemplatesEditPage />
        </Route>

        <Route path="/pitchbox/send-email" exact>
          <SendEmailForm />
        </Route>

        <Route path="/pitchbox/mailing" exact>
          <MailingListPage />
        </Route>
        <Route path="/pitchbox/mailing/:id" exact>
          <MailingEmailsListPage />
        </Route>
        <Route path="/pitchbox/emails/inbox" exact>
          <InboxPage />
        </Route>
        <Route path="/pitchbox/emails/inbox/:id" exact>
          <InboundEmailPage />
        </Route>
        <Route path="/twitter/follows" exact>
          <TwitterFollowPage />
        </Route>
        <Route path="/twitter/send-tweet" exact>
          <SendTweetPage />
        </Route>
        <Route path="/backlink-spy/summary" exact>
          <BacklinkSpySummary />
        </Route>
        <Route path="/facebook" exact>
          <InProgressPage />
        </Route>
        <Route path="/linkedin" exact>
          <InProgressPage />
        </Route>
        <Route path="/skype" exact>
          <InProgressPage />
        </Route>

        <Route path="/user/preferences" exact>
          <UserPreferencesPage />
        </Route>

        <RouteRol
          path="/usuarios/listar"
          exact
          rol="gestor"
          page404={<Page404 />}
        >
          <UsuarioPage />
        </RouteRol>

        <RouteRol
          path="/usuarios/agregar"
          exact
          rol="gestor"
          page404={<Page404 />}
        >
          <UsuarioForm />
        </RouteRol>

        <RouteRol
          path="/usuarios/editar/:id"
          exact
          rol="gestor"
          page404={<Page404 />}
        >
          <UsuarioEditPage />
        </RouteRol>

        <RouteRol
          path="/emails/listar"
          exact
          rol={["gestor"]}
          page404={<Page404 />}
        >
          <EmailPage />
        </RouteRol>

        <RouteRol
          path="/emails/agregar"
          exact
          rol="gestor"
          page404={<Page404 />}
        >
          <EmailForm />
        </RouteRol>

        <RouteRol
          path="/emails/editar/:id"
          exact
          rol="gestor"
          page404={<Page404 />}
        >
          <EmailEditPage />
        </RouteRol>

        <Route render={routeProps => <Page404 {...routeProps} />} />
      </Switch>
    </>
  );
};

export default withRouter(AuthRoutes);
